// import Canada from '../assets/filings/canada.png';
// import CanadaSource from '../assets/filings/canada_source.png';
import UnitedKingdom from '../assets/filings/united_kingdom.png';
import CompaniesHouse from '../assets/filings/companies_house.png';
import LondonStockExchange from '../assets/filings/london_stock_exchange.png';
import TheGazette from '../assets/filings/the_gazette.png';
import UnitedStates from '../assets/filings/united_states.png';
import UnitedStatesSource from '../assets/filings/united_states_source.png';

export const countryList = [
  {
    id: 3,
    name: 'United Kingdom',
    image: UnitedKingdom,
    ticker: 'GB',
    sources: [
      {
        id: 1,
        isSource: true,
        name: 'Companies House',
        image: CompaniesHouse,
        parentTicker: 'GB',
        parentName: 'United Kingdom',
        ticker: 'companieshouse-gb',
      },
      {
        id: 3,
        isSource: true,
        name: 'The Gazette',
        image: TheGazette,
        parentTicker: 'GB',
        parentName: 'United Kingdom',
        ticker: 'gazette-gb',
      },
    ],
  },
  {
    id: 4,
    name: 'United States',
    image: UnitedStates,
    ticker: 'US',
    sources: [
      {
        id: 1,
        isSource: true,
        name: 'Securities and Exchange Commission',
        image: UnitedStatesSource,
        parentTicker: 'US',
        parentName: 'United States',
        ticker: 'sec-us',
      },
    ],
  },
  {
    id: 5,
    name: 'More coming soon',
    isMore: true,
    ticker: '',
    sources: [],
  },
];

export const filingsProviderCode = ':providerCode(companieshouse-gb|gazette-gb|sec-us)';

export default countryList;
