import {
  useState, useRef, useContext, useEffect,
} from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment';
import { faRetweet } from '@fortawesome/pro-regular-svg-icons/faRetweet';
import { faHeart } from '@fortawesome/pro-regular-svg-icons/faHeart';
import axios from 'axios';
import { faLanguage as faLanguageRegular } from '@fortawesome/pro-regular-svg-icons/faLanguage';
import { faLanguage as faLanguageLight } from '@fortawesome/pro-light-svg-icons/faLanguage';

import { calculateTimeAgo, returnCDNFoxyImageUrl } from '../../../../../helpers/helpers';
import { getAverageSentimentStoryText } from '../../../../../helpers/sentimentHelpers';

import { setStoryImageOpened } from '../../../../../actions/stories.actions';

import useFinprompt from '../../../../../providers/FinpromptProvider/useFinprompt';

import StoriesSvc from '../../../../../services/StoriesSvc';
import piwikContext from '../../../../../services/piwikContext';

import { GOLD_PLAN } from '../../../../../data/subscriptionPlans';
import { CF_URL_FOR_FINPROMPT } from '../../../../../data/environment';

import Tooltip from '../../../../Tooltip';
import TwitterImgSliderModal from '../../../../TrendingStories/StoryCard/TwitterImgSliderModal';

import Styles from '../styles.module.scss';

const cx = classNames.bind(Styles);

const StoryCard = (props) => {
  const { story, hasBigScreenDesign } = props;

  const dispatch = useDispatch();
  const { translate_all: showTranslation, translationSupportedLanguages, languages } = useSelector(
    ({ storyFilters }) => storyFilters,
  );
  const { piwikEnabled } = useContext(piwikContext);
  const { isFinpromptPages } = useFinprompt();

  const [isFoxy, setIsFoxy] = useState(true);
  const [imageLoadingError, setImageLoadingError] = useState(false);
  const [domainLoadingError, setDomainLoadingError] = useState(false);
  const [currentIcon, setCurrentIcon] = useState(faLanguageLight);
  const [isTranslationShown, setIsTranslationShow] = useState(showTranslation);
  const [translation, setTranslation] = useState({
    title: story?.translations?.[0]?.title,
    description: story?.translations?.[0]?.description,
  });

  const twitterImgSliderModalRef = useRef();
  const sourceCancelToken = useRef(axios.CancelToken.source());

  const sentimentValue = (story.sentiment || story.sentiment === 0)
    && getAverageSentimentStoryText(story.sentiment);

  const handleImageError = () => {
    if (isFoxy) {
      return setIsFoxy(false);
    }
    setImageLoadingError(true);
  };
  const handleDomainError = () => {
    setDomainLoadingError(true);
  };

  const openStory = (e) => {
    if (e) e.stopPropagation();
    const url = story.url || story.expanded_url || (story.type === 'Tweet' ? `https://twitter.com/i/web/status/${story.additional_data.tweet_id}` : '');
    const isSingleStoryUrl = story.lowest_premium_plan === GOLD_PLAN && story.type !== 'Tweet';
    const mainURL = isSingleStoryUrl || !url ? `${isFinpromptPages ? CF_URL_FOR_FINPROMPT : ''}/news/stories/${story.uuid_title}` : url;

    window.open(mainURL, '_blank')?.focus?.();
  };

  const openTwitterImagesModal = (e) => {
    e.stopPropagation();

    if (twitterImgSliderModalRef && twitterImgSliderModalRef.current) {
      twitterImgSliderModalRef.current.handleShow();
    }
    dispatch(setStoryImageOpened(true));
  };

  const getFollowersFormat = (num) => {
    switch (true) {
      case num > 999 && num < 999999:
        return `${Math.floor(num / 1000)}k`;
      case num > 999999:
        return `${Math.floor(num / 1000000)}m`;
      default:
        return num;
    }
  };

  const tweetAction = (type) => {
    const y = window.outerHeight / 2 + window.screenY - (570 / 2);
    const x = window.outerWidth / 2 + window.screenX - (520 / 2);

    if (type === 'in_reply_to') {
      if (piwikEnabled) _paq.push(['trackEvent', 'Story interaction', 'Tweet reply']);

      window.open(
        `https://twitter.com/intent/tweet?${type}=${story.additional_data.tweet_id}&original_referer=${window.location.href}`,
        '_blank',
        `location=yes,height=570,width=520,scrollbars=yes,status=yes,top=${y},left=${x}`,
      )?.focus?.();
    } else {
      if (piwikEnabled && type === 'retweet') _paq.push(['trackEvent', 'Story interaction', 'Retweet']);
      if (piwikEnabled && type === 'favorite') _paq.push(['trackEvent', 'Story interaction', 'Tweet like']);

      window.open(
        `https://twitter.com/intent/${type}?tweet_id=${story.additional_data.tweet_id}&original_referer=${window.location.href}`,
        '_blank',
        `location=yes,height=570,width=520,scrollbars=yes,status=yes,top=${y},left=${x}`,
      )?.focus?.();
    }
  };

  const imgURL = story.imageUrls?.[0];
  const domainImgUrl = story.type === 'Tweet' ? story.author_image_url : story.domain_cached_large_logo_url;
  const imageURL = returnCDNFoxyImageUrl({
    isFoxy, imageURL: imgURL,
  });

  const setTranslations = async () => {
    if (!story?.translations?.length && languages !== 'en') {
      const isTranslationSupported = translationSupportedLanguages?.map((el) => el.code).toString().split(',')?.includes(story.lang);
      if (!story?.translations?.length && isTranslationSupported) {
        if (!sourceCancelToken.current) {
          sourceCancelToken.current = axios.CancelToken.source();
        }

        const translatedData = await StoriesSvc.getTranslatedStory(story.uuid, 'en', sourceCancelToken.current.token);
        if (translatedData?.cancelled) return;
        story.translations = translatedData?.data?.article_translations;
        setTranslation({
          title: story?.translations[0]?.title,
          description: story?.translations[0]?.description,
        });
      }
    }
    if (story?.translations !== null && story?.translations && story?.translations?.length) {
      setTranslation({
        title: story?.translations[0]?.title,
        description: story?.translations[0]?.description,
      });
    }
  };

  const toggleTranslation = (e) => {
    e.stopPropagation();
    const newState = !isTranslationShown;

    if (translation.title) {
      setIsTranslationShow(newState);
    }
  };

  const mouseEnterHandler = () => {
    setCurrentIcon(faLanguageRegular);
  };

  const mouseLeaveHandler = () => {
    setCurrentIcon(faLanguageLight);
  };
  useEffect(() => () => {
    if (sourceCancelToken.current) {
      sourceCancelToken.current.cancel();
    }
  }, []);
  useEffect(() => {
    const isTranslationSupported = translationSupportedLanguages?.map((el) => el.code).toString().split(',')?.includes(story.lang);
    if (isTranslationSupported && showTranslation) {
      setTranslations();
    } else if (sourceCancelToken.current) {
      sourceCancelToken.current.cancel();
      sourceCancelToken.current = null;
    }
  }, [showTranslation, story.translations]);

  const isTranslationSupported = translationSupportedLanguages?.map((el) => el.code)?.toString()?.split(',')?.includes(story.lang);
  return (
    <div className={cx('story-card', { big_screen: hasBigScreenDesign })}>
      <div className={Styles['story-card-img']}>
        {imageURL && !imageLoadingError ? (
          <img
            src={imageURL}
            loading="lazy"
            onError={handleImageError}
            alt=""
            onClick={openTwitterImagesModal}
          />
        ) : (
          <>
            {domainImgUrl && !domainLoadingError && (
              <img
                src={domainImgUrl}
                loading="lazy"
                onError={handleDomainError}
                alt=""
                onClick={openTwitterImagesModal}
              />
            )}
          </>
        )}
      </div>
      <div className={Styles['story-card-content']}>
        <div className={Styles['story-card-body']}>
          <div className={Styles['story-card-body-title']} onClick={openStory}>
            {(isTranslationShown && translation?.title) ? translation?.title : story.title}
          </div>
          <div className={Styles['story-card-details']}>
            <div className={Styles['story-card-details-auth']}>
              {story.type === 'Tweet' ? (
                <>
                  <img src={story.author_image_url} alt="" />
                  <FontAwesomeIcon
                    icon={faTwitter}
                    className={cx('twitter-icon')}
                  />
                  <div className={Styles['story-card-details-auth-name']}>
                    @
                    {story.author_screen_name}
                  </div>
                  <span className={cx('icons--container')}>
                    <span className={cx('followers-count')}>
                      follower / ing ratio:&nbsp;
                      {getFollowersFormat(story.followers_following_ratio)}
                    </span>
                    <span className={cx('tweet-action')} onClick={() => tweetAction('in_reply_to')}>
                      <FontAwesomeIcon icon={faComment} />
                    </span>
                    <span className={cx('tweet-action')} onClick={() => tweetAction('retweet')}>
                      <FontAwesomeIcon icon={faRetweet} />
                    </span>
                    <span className={cx('tweet-action')} onClick={() => tweetAction('favorite')}>
                      <FontAwesomeIcon icon={faHeart} />
                    </span>
                  </span>
                </>
              ) : (
                <>
                  <img src={story.domain_cached_logo_url} alt="" />
                  <div className={Styles['story-card-details-auth-name']}>{story.domain_name}</div>
                </>
              )}
            </div>
            <div className={Styles['story-card-details-time']}>
              {calculateTimeAgo(story.publishTime, true)}
            </div>
            <div
              className={cx('story-card-details-score')}
            >
              <Tooltip
                className={Styles['single-story-icon-tooltip']}
                icon={(
                  <span>
                    {Math.round(story.score)}
                    %
                  </span>
                )}
                hasBigScreenDesign
                delayTooltip={2000}
                allowTooltipClick
              >
                CityFALCON Score showing relevance&nbsp;
                of story to you and your chosen topics.
                <a href="https://www.cityfalcon.com/features/cf-score" rel="noopener noreferrer">
                  How does it work?
                </a>
              </Tooltip>
            </div>
            {showTranslation && isTranslationSupported && story.lang !== 'en' && (
            <span
              className={cx('details-header__info--translate', { disabled: translation && !translation.title })}
              onMouseEnter={mouseEnterHandler}
              onMouseLeave={mouseLeaveHandler}
            >

              <Tooltip
                className={cx('translate--tooltip')}
                icon={(<FontAwesomeIcon icon={currentIcon} />)}
                hasBigScreenDesign
                isBigScreen
              >
                <span className={cx('title')}>
                  {(() => {
                    if (translation && translation.title) {
                      if (isTranslationShown) {
                        return (
                          <>
                            <div className={cx('translated--from--title')}>
                              {`Translated from ${translationSupportedLanguages
                                ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                            </div>
                            <div
                              className={cx('translate--title')}
                              onClick={toggleTranslation}
                            >
                              Show Original
                            </div>
                          </>
                        );
                      }
                      return (
                        <>
                          <div className={cx('translated--from--title')}>
                            {`Original in ${translationSupportedLanguages
                              ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                          </div>
                          <div
                            className={cx('translate--title')}
                            onClick={toggleTranslation}
                          >
                            Translate to English
                          </div>
                        </>
                      );
                    }
                    return 'Translating to English...';
                  })()}
                </span>
              </Tooltip>
              <span className={cx('title')}>
                {(() => {
                  if (translation && translation.title) {
                    if (isTranslationShown) {
                      return (
                        <>
                          <span className={cx('translated--from--title')}>
                            {`Translated from ${translationSupportedLanguages
                              ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                          </span>
                          <span
                            className={cx('translate--title')}
                            onClick={toggleTranslation}
                          >
                            Show Original
                          </span>
                        </>
                      );
                    }
                    return (
                      <>
                        <span className={cx('translated--from--title')}>
                          {`Original in ${translationSupportedLanguages
                            ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                        </span>
                        <span
                          className={cx('translate--title')}
                          onClick={toggleTranslation}
                        >
                          Translate to English
                        </span>
                      </>
                    );
                  }
                  return 'Translating to English...';
                })()}
              </span>
            </span>
            )}
          </div>
        </div>
        <div className={cx('story-card-sentiment', (sentimentValue || {}).type)}>
          {sentimentValue && (
            <Tooltip
              icon={<FontAwesomeIcon icon={sentimentValue.icon} />}
              hasBigScreenDesign
              delayTooltip={2000}
              allowTooltipClick
            >
              Sentiment:
              {' '}
              {story.sentiment}
              %.
              <br />
              Ranges from -100% to +100%.
              {' '}
              <a href="https://www.cityfalcon.ai/features/sentiment" target="_blank" rel="noopener noreferrer">
                <span>Learn more</span>
              </a>
            </Tooltip>
          )}
        </div>
      </div>
      {(!imageLoadingError || !domainLoadingError) && (
        <TwitterImgSliderModal
          ref={(el) => { twitterImgSliderModalRef.current = el; }}
          images={imgURL && !imageLoadingError ? story.imageUrls : [domainImgUrl]}
          onClose={() => dispatch(setStoryImageOpened(false))}
        />
      )}
    </div>
  );
};

export default StoryCard;
