import {
  UK_PRIVATE_COMPANY_TOP_YEAR_FILTERS,
  UK_PRIVATE_COMPANY_FUNDRAISING_FILTERS,
  UK_PRIVATE_COMPANY_SINGLE_FUNDRAISING_FILTERS,
  UK_PRIVATE_COMPANY_INVESTORS_FILTERS,
  UK_PRIVATE_COMPANY_SINGLE_INVESTORS_FILTERS,
  UK_PRIVATE_COMPANY_MARKET_FILTERS,
  UK_PRIVATE_COMPANY_ROI_FILTERS,
  UK_PRIVATE_COMPANY_HIDE_ABNORMAL,
  UK_PRIVATE_COMPANY_STATUS_ALL_COMPANIES_FILTERS,
  UK_PRIVATE_COMPANY_CHANGES_COMPANIES_STATUS_FILTERS,
} from '../actions/ukPrivateCompany.actions';
import StorageSvc from '../services/StorageSvc';

const initialState = {
  fundraisingYear: '',
  investorsYear: '',
  marketYear: '',
  roiYear: '',
  fundraisingFilter: {
    currentPage: 1,
    pageSize: '10',
  },
  singleFundraisingFilter: {
    currentPage: 1,
    pageSize: '10',
  },
  investorsFilter: {
    currentPage: 1,
    pageSize: '10',
    sortField: 'investmentsMade',
    sortOrder: 'desc',
  },
  singleInvestorsFilter: {
    currentPage: 1,
    pageSize: '10',
    sortField: '',
    sortOrder: '',
  },
  marketFilter: {
    currentPage: 1,
    pageSize: '10',
  },
  roiFilter: {
    currentPage: 1,
    pageSize: '10',
  },
  hideAbnormal: true,
  statusAllCompaniesFilter: {
    selectedYear: (JSON.parse(StorageSvc.getItem('statusAllCompaniesFilter')) || {}).selectedYear || '',
    selectedPeriod: (JSON.parse(StorageSvc.getItem('statusAllCompaniesFilter')) || {}).selectedPeriod || '',
  },
  changesCompaniesStatusFilter: {
    selectedYear: (JSON.parse(StorageSvc.getItem('changesCompaniesStatusFilter')) || {}).selectedYear || '',
    selectedPeriod: (JSON.parse(StorageSvc.getItem('changesCompaniesStatusFilter')) || {}).selectedPeriod || '',
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UK_PRIVATE_COMPANY_TOP_YEAR_FILTERS:
      return {
        ...state,
        ...payload,
      };
    case UK_PRIVATE_COMPANY_INVESTORS_FILTERS:
      return {
        ...state,
        investorsFilter: {
          ...state.investorsFilter,
          ...payload,
        },
      };
    case UK_PRIVATE_COMPANY_SINGLE_INVESTORS_FILTERS:
      return {
        ...state,
        singleInvestorsFilter: {
          ...state.singleInvestorsFilter,
          ...payload,
        },
      };
    case UK_PRIVATE_COMPANY_FUNDRAISING_FILTERS:
      return {
        ...state,
        fundraisingFilter: {
          ...state.fundraisingFilter,
          ...payload,
        },
      };
    case UK_PRIVATE_COMPANY_SINGLE_FUNDRAISING_FILTERS:
      return {
        ...state,
        singleFundraisingFilter: {
          ...state.singleFundraisingFilter,
          ...payload,
        },
      };
    case UK_PRIVATE_COMPANY_MARKET_FILTERS:
      return {
        ...state,
        marketFilter: {
          ...state.marketFilter,
          ...payload,
        },
      };
    case UK_PRIVATE_COMPANY_ROI_FILTERS:
      return {
        ...state,
        roiFilter: {
          ...state.roiFilter,
          ...payload,
        },
      };
    case UK_PRIVATE_COMPANY_HIDE_ABNORMAL:
      return {
        ...state,
        hideAbnormal: payload,
      };
    case UK_PRIVATE_COMPANY_STATUS_ALL_COMPANIES_FILTERS:
      return {
        ...state,
        statusAllCompaniesFilter: {
          ...state.statusAllCompaniesFilter,
          ...payload,
        },
      };
    case UK_PRIVATE_COMPANY_CHANGES_COMPANIES_STATUS_FILTERS:
      return {
        ...state,
        changesCompaniesStatusFilter: {
          ...state.changesCompaniesStatusFilter,
          ...payload,
        },
      };
    default:
      return state;
  }
};
