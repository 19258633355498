import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Tooltip as BootStrapTooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { faLanguage as faLanguageRegular } from '@fortawesome/pro-regular-svg-icons/faLanguage';
import { faLanguage as faLanguageLight } from '@fortawesome/pro-light-svg-icons/faLanguage';
import { calculateTimeAgo } from '../../../../helpers/helpers';
import withComponentName from '../../../../decorators/withComponentName';
import Tooltip from '../../../Tooltip';

import Preloader from '../../../Preloader';

import Styles from './styles.module.scss';
import StoriesSvc from '../../../../services/StoriesSvc';

const cx = classNames.bind(Styles);

export const SimilarStories = ({
  similarStories,
  similarStoriesCount,
  closeSimilars,
  openStory,
  openURL,
  tilesView,
  width,
  hasBigScreenDesign,
  isCompactView,
  expanded,
  storyId,
  showTranslation,
  translationSupportedLanguages,
  isTranslationSupported,
}) => (
  (Boolean(similarStoriesCount)
    && (similarStories.loading || Boolean(similarStories.stories.length))
  ) && (
    <div
      className={cx('similar-stories-block', {
        tiles: tilesView, big_screen: hasBigScreenDesign, compact: isCompactView, expanded,
      })}
    >
      <FontAwesomeIcon icon={faXmark} className={cx('close')} onClick={closeSimilars} />
      <div className={cx('similar-stories-header')}>
        <span className={cx('title')}>
          {!similarStories.loading && (similarStoriesCount >= 20 ? '20+' : similarStoriesCount)}
          &nbsp;Similar&nbsp;
          {similarStoriesCount === 1 ? 'Story' : 'Stories'}
        </span>
        {similarStories.loading && <Preloader static loading className={cx('spinner')} />}
      </div>
      {!similarStories.loading && similarStories.stories.map((story, index) => (
        <SimilerStoryRow
          key={story.id}
          story={story}
          openStory={openStory}
          openURL={openURL}
          width={width}
          tilesView={tilesView}
          lastChild={index === similarStories.stories.length - 1}
          showTranslation={showTranslation}
          translationSupportedLanguages={translationSupportedLanguages}
          isTranslationSupported={isTranslationSupported}
        />
      ))}
      <div className={cx('similar-stories-footer')}>
        <Link
          className={cx('similar-stories-footer__link')}
          to={`/news/similar/${[storyId]}`}
        >
          See Full Coverage
        </Link>
      </div>
    </div>
  )
);

const SimilerStoryRow = ({
  story,
  openStory,
  openURL,
  width,
  tilesView,
  lastChild,
  showTranslation,
  translationSupportedLanguages,
  isTranslationSupported,
}) => {
  const [titleTooltip, setTitleTooltip] = useState(false);
  const [tooltipWidth, setTooltipWidth] = useState();
  const [timeTooltip, setTimeTooltip] = useState(false);
  const [timeTooltipWidth, setTimeTooltipWidth] = useState();
  const [domainTooltip, setDomainTooltip] = useState(false);
  const [domainTooltipWidth, setDomainTooltipWidth] = useState();
  const [isTranslationShown, setIsTranslationShow] = useState(showTranslation);
  const [translation, setTranslation] = useState({
    title: story?.translations?.[0]?.title,
    description: story?.translations?.[0]?.description,
  });
  const sourceCancelToken = useRef(axios.CancelToken.source());

  const {
    languages,
  } = useSelector((state) => state.storyFilters);

  const [currentIcon, setCurrentIcon] = useState(faLanguageLight);
  const setTitleRef = (refValue) => {
    if (!refValue) return;
    setTooltipWidth(`${refValue.parentNode.offsetWidth}px`);
    setTitleTooltip(refValue.offsetWidth < refValue.scrollWidth);
  };

  const setTimeRef = (refValue) => {
    if (!refValue) return;
    setTimeTooltipWidth(`${refValue.parentNode.offsetWidth}px`);
    setTimeTooltip(refValue.offsetWidth < refValue.scrollWidth);
  };

  const setDomainRef = (refValue) => {
    if (!refValue) return;
    setDomainTooltipWidth(`${refValue.parentNode.offsetWidth}px`);
    setDomainTooltip(refValue.offsetWidth < refValue.scrollWidth);
  };

  const setTranslations = async () => {
    if (!story.translations?.length && languages !== 'en') {
      const isTranslationSupported = translationSupportedLanguages?.map((el) => el.code).toString().split(',')?.includes(story.lang);
      if (!story?.translations?.length && isTranslationSupported) {
        if (!sourceCancelToken.current) {
          sourceCancelToken.current = axios.CancelToken.source();
        }
        const translatedData = await StoriesSvc.getTranslatedStory(story.uuid, 'en', sourceCancelToken.current.token);
        if (translatedData?.cancelled) return;
        story.translations = translatedData?.data?.article_translations;
        setTranslation({
          title: story?.translations?.[0]?.title,
          description: story?.translations?.[0]?.description,
        });
      }
    }
    if (story.translations !== null && story.translations && story.translations.length) {
      setTranslation({
        title: story?.translations[0]?.title,
        description: story?.translations[0]?.description,
      });
    }
  };

  const toggleTranslation = (e) => {
    e.stopPropagation();
    const newState = !isTranslationShown;

    if (translation.title) {
      setIsTranslationShow(newState);
    }
  };

  const mouseEnterHandler = () => {
    setCurrentIcon(faLanguageRegular);
  };

  const mouseLeaveHandler = () => {
    setCurrentIcon(faLanguageLight);
  };

  useEffect(() => () => {
    if (sourceCancelToken.current) {
      sourceCancelToken.current.cancel();
    }
  }, []);

  useEffect(() => {
    const isTranslationSupported = translationSupportedLanguages?.map((el) => el.code).toString().split(',')?.includes(story.lang);
    if (isTranslationSupported && showTranslation) {
      setTranslations();
    } else if (sourceCancelToken.current) {
      sourceCancelToken.current.cancel();
      sourceCancelToken.current = null;
    }
  }, [showTranslation, story.translations]);

  return (
    <div
      key={story.id}
      className={cx('similar-story', { 'last-child': lastChild })}
    >
      <OverlayTrigger
        placement={tilesView ? 'top-start' : 'top'}
        overlay={(
          <BootStrapTooltip
            id={`similer_story_tooltip_${story.id}`}
            bsPrefix={cx({ hidden: !titleTooltip })}
            style={{
              width: tooltipWidth,
            }}
          >
            {(isTranslationShown && translation?.title) ? translation?.title : story.title}
          </BootStrapTooltip>
        )}
      >
        <h4 className={cx('title')} ref={setTitleRef} onClick={(e) => openStory(e, false, story)}>{(isTranslationShown && translation?.title) ? translation?.title : story.title}</h4>
      </OverlayTrigger>
      {showTranslation && isTranslationSupported && story.lang !== 'en' && (
      <span
        className={cx('details-header__info--translate')}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
      >
        <Tooltip
          className={Styles.bookmarks_tooltip}
          icon={(<FontAwesomeIcon icon={currentIcon} />)}
          hasBigScreenDesign
          isBigScreen
        >
          <span className={cx('translate--title--container')}>
            {(() => {
              if (translation && translation.title) {
                if (isTranslationShown) {
                  return (
                    <>
                      <div className={cx('translated--from--title')}>
                        {`Translated from ${translationSupportedLanguages
                          ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                      </div>
                      <div
                        className={cx('translate--title')}
                        onClick={toggleTranslation}
                      >
                        Show Original
                      </div>
                    </>
                  );
                }
                return (
                  <>
                    <div className={cx('translated--from--title')}>
                      {`Original in ${translationSupportedLanguages
                        ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                    </div>
                    <div
                      className={cx('translate--title')}
                      onClick={toggleTranslation}
                    >
                      Translate to English
                    </div>
                  </>
                );
              }
              return 'Translating to English...';
            })()}
          </span>
        </Tooltip>
      </span>
      )}
      <span className={cx('details-header__info--about')} onClick={(e) => e.stopPropagation()}>
        <span className={cx('details-header__info--about__container')}>
          <img
            className={Styles.logo}
            src={(
              story.type === 'Tweet' ? (
                story.author_image_url
              ) : (
                story.domain_cached_logo_url
              )
            )}
            alt="Logo"
            style={{ visibility: 'hidden' }}
            onLoad={({ target }) => target.style.removeProperty('visibility')}
          />
          {story.type === 'Tweet' ? (
            <>
              <FontAwesomeIcon icon={faTwitter} className="twitter-icon" />
              <OverlayTrigger
                placement={tilesView ? 'top-start' : 'top'}
                overlay={(
                  <BootStrapTooltip
                    id={`similer_story_tooltip_${story.id}`}
                    bsPrefix={cx({ hidden: !domainTooltip })}
                    style={{
                      width: domainTooltipWidth,
                    }}
                  >
                    @
                    {story.author_screen_name}
                  </BootStrapTooltip>
                )}
              >

                <span
                  className={cx('source-name')}
                  onClick={(e) => openURL(e, `https://www.twitter.com/intent/user?screen_name=${story.author_screen_name}`)}
                  ref={setDomainRef}
                >
                  @
                  {story.author_screen_name}
                </span>

              </OverlayTrigger>
            </>
          ) : (
            <OverlayTrigger
              placement={tilesView ? 'top-start' : 'top'}
              overlay={(
                <BootStrapTooltip
                  id={`similer_story_tooltip_${story.id}`}
                  bsPrefix={cx({ hidden: !domainTooltip })}
                  style={{
                    width: domainTooltipWidth,
                  }}
                >
                  {story.domain_name}
                </BootStrapTooltip>
              )}
            >
              <span className={cx('source-name')} ref={setDomainRef}>{story.domain_name}</span>
            </OverlayTrigger>

          )}
        </span>
        <OverlayTrigger
          placement={tilesView ? 'top-start' : 'top'}
          overlay={(
            <BootStrapTooltip
              id={`similer_story_tooltip_${story.id}`}
              bsPrefix={cx({ hidden: !timeTooltip })}
              style={{
                width: timeTooltipWidth,
              }}
            >
              {calculateTimeAgo(story.publishTime, width <= 1500)}
            </BootStrapTooltip>
          )}
        >
          <span className={cx('publish_time')} ref={setTimeRef}>{calculateTimeAgo(story.publishTime, width <= 1500)}</span>
        </OverlayTrigger>
      </span>
    </div>
  );
};

SimilarStories.defaultProps = {
  similarStoriesCount: 0,
};

SimilarStories.propTypes = {
  similarStories: PropTypes.shape({
    closed: PropTypes.bool,
    stories: PropTypes.array,
    loading: PropTypes.bool,
  }).isRequired,
  similarStoriesCount: PropTypes.number,
  openStory: PropTypes.func.isRequired,
  openURL: PropTypes.func.isRequired,
  closeSimilars: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  storyId: PropTypes.string.isRequired,
};

export default withComponentName(SimilarStories);
