import classNames from 'classnames/bind';
import { isMobile } from 'react-device-detect';
import { memo, useState } from 'react';
import loadable from '@utils/loadable';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle as faPlayCircleLight } from '@fortawesome/pro-light-svg-icons/faPlayCircle';
import { faPlayCircle as faPlayCircleRegular } from '@fortawesome/pro-regular-svg-icons/faPlayCircle';

import Tooltip from '../Tooltip';

import Styles from './styles.module.scss';

const WistiaPlayer = loadable(() => import('../Shared/WistiaPlayer'));

const cx = classNames.bind(Styles);

const PlayVideoComponent = ({
  videoLink,
  videoLinkMobile,
  isSentiment,
  renderOutside,
  additionalStyles,
  tooltipAdditionalContentStyle,
}) => {
  const mainWidth = useSelector(({ common }) => (
    common.width
  ));

  const [currentIcon, setCurrentIcon] = useState(faPlayCircleLight);
  let videoUrl = mainWidth > 1024 ? videoLink : videoLinkMobile;
  if (!videoUrl) {
    videoUrl = videoLink || videoLinkMobile;
  }

  const mouseEnterHandler = () => {
    setCurrentIcon(faPlayCircleRegular);
  };

  const mouseLeaveHandler = () => {
    setCurrentIcon(faPlayCircleLight);
  };

  const contentStyleTT = renderOutside ? {
    transform: 'translate(calc(-50% + 7px), calc(-100% + 7px))',
    zIndex: '9494995',
    width: '186px',
    textAlign: 'center',
    borderRadius: '5px',
  } : null;

  return (
    <div className={`${cx('play_video')} ${additionalStyles}`}>
      {isSentiment && (
        <Tooltip
          className={cx('sentiments_tooltip_icon', 'sentiments_tooltip_message')}
        >
          Sentiment quantifies how news and social media feel about this topic.
          <div><a href="https://www.cityfalcon.ai/features/sentiment">How does it work?</a></div>
        </Tooltip>
      )}
      <div
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
        className={cx('play_video_container')}
      >
        <Tooltip
          icon={(
            <div>
              <FontAwesomeIcon
                icon={currentIcon}
                className={cx('play_video_icon')}
              />
              <WistiaPlayer
                className={cx('play_video_player')}
                url={videoUrl}
                style={{
                  position: 'absolute',
                  opacity: '0',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                }}
                playVideo
                controls
                config={{
                  options: isMobile ? { playsinline: false } : {},
                }}
              />
            </div>
          )}
          renderOutside={renderOutside}
          skipSmall={renderOutside}
          hideWithOverflow={renderOutside}
          contentStyle={contentStyleTT}
          className={`${cx('play_video_tooltip')} ${tooltipAdditionalContentStyle}`}
        >
          <span>See Video about the Feature</span>
        </Tooltip>
      </div>

    </div>
  );
};

PlayVideoComponent.defaultProps = {
  videoLink: '',
  isSentiment: false,
  additionalStyles: '',
  tooltipAdditionalContentStyle: '',
};

export default memo(PlayVideoComponent);
