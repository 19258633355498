import moment from 'moment';
import LodashOmit from 'lodash-es/omit';
import convertToDecimal from './convertToDecimal';

import flags from '../data/directory/flags';

import { ONE_DAY, ONE_WEEK } from '../data/directory/topic_classes/ChartFilters';

/* eslint-disable no-underscore-dangle */
moment.fn.round = function round(precision, initialKey, direction = 'round') {
  let key = initialKey;
  const methods = {
    hours: {
      name: 'Hours',
      maxValue: 24,
    },
    minutes: {
      name: 'Minutes',
      maxValue: 60,
    },
    seconds: {
      name: 'Seconds',
      maxValue: 60,
    },
    milliseconds: {
      name: 'Milliseconds',
      maxValue: 1000,
    },
  };
  const keys = {
    mm: methods.milliseconds.name,
    milliseconds: methods.milliseconds.name,
    Milliseconds: methods.milliseconds.name,
    s: methods.seconds.name,
    seconds: methods.seconds.name,
    Seconds: methods.seconds.name,
    m: methods.minutes.name,
    minutes: methods.minutes.name,
    Minutes: methods.minutes.name,
    H: methods.hours.name,
    h: methods.hours.name,
    hours: methods.hours.name,
    Hours: methods.hours.name,
  };
  let value = 0;
  let rounded = false;
  let subRatio = 1;
  let maxValue;

  // make sure key is plural
  if (key.length > 1 && key !== 'mm' && key.slice(-1) !== 's') {
    key += 's';
  }
  key = keys[key].toLowerCase();

  // control
  if (!methods[key]) {
    throw new Error('The value to round is not valid. Possibles ["hours", "minutes", "seconds", "milliseconds"]');
  }

  const get = `get${methods[key].name}`;
  const set = `set${methods[key].name}`;

  Object.entries(methods).forEach(([k, method]) => {
    if (k === key) {
      value = this._d[get]();
      maxValue = method.maxValue;
      rounded = true;
    } else if (rounded) {
      subRatio *= method.maxValue;
      value += this._d[`get${method.name}`]() / subRatio;
      this._d[`set${method.name}`](0);
    }
  });

  value = Math[direction](value / precision) * precision;
  value = Math.min(value, maxValue);
  this._d[set](value);

  return this;
};
/* eslint-enable no-underscore-dangle */

const css = {
  reset() {
    this.height = 430;
    this.marginL = '2';
    this.marginR = '70';
    this.marginT = '2';
    this.marginB = '50';
  },
};
css.reset();

const timeInterval = {
  '1h': {
    marketShareXInterval: '1 minutes',
  },
  '1d': {
    marketShareXInterval: '10 minutes',
  },
  '1w': {
    marketShareXInterval: '30 minutes',
  },
  '1m': {
    marketShareXInterval: '2 hours',
  },
  '3m': {
    marketShareXInterval: '2 hours',
  },
  '1y': {
    marketShareXInterval: '12 hours',
  },
  All: {
    marketShareXInterval: '24 hours',
  },
};
const graphColors = [
  {
    lineColor: '215, 227, 243',
    backgroundColor: '245, 248, 252',
  },
  {
    lineColor: '176, 198, 230',
    backgroundColor: '235, 241, 250',
  },
  {
    lineColor: '136, 170, 218',
    backgroundColor: '225, 234, 246',
  },
  {
    lineColor: '97, 141, 205',
    backgroundColor: '212, 224, 242',
  },
  {
    lineColor: '57, 113, 193',
    backgroundColor: '205, 219, 240',
  },
  {
    lineColor: '207, 244, 236',
    backgroundColor: '243, 252, 250',
  },
  {
    lineColor: '158, 232, 217',
    backgroundColor: '231, 249, 246',
  },
  {
    lineColor: '110, 221, 197',
    backgroundColor: '218, 247, 241',
  },
  {
    lineColor: '61, 209, 178',
    backgroundColor: '206, 244, 236',
  },
  {
    lineColor: '13, 198, 159',
    backgroundColor: '194, 241, 231',
  },
];

export function optimizePoints(points, timeFilter, optimisationKey = 'last_updated') {
  const result = {};
  const timePeriod = timeInterval[timeFilter].marketShareXInterval.split(' ');

  points.forEach((point) => {
    const currentTime = moment(point[optimisationKey]).round(timePeriod[0], timePeriod[1]).format();

    result[currentTime] = result[currentTime] || {};
    result[currentTime] = point;
  });

  return Object.values(result);
}

function responsiveCSS(layout, hasBigScreenDesign = false, isMobile = false) {
  if ((window.innerWidth < 767) || isMobile) {
    css.marginR = '6';
    css.marginB = '75';
  } else if (window.innerWidth <= 1024) {
    css.marginR = '60';
    css.marginB = '75';
  } else {
    css.reset();
  }

  if (hasBigScreenDesign && window.innerWidth >= 2800) {
    css.height = 1066;
    css.marginR = '140';
    css.marginB = '100';
  }

  layout.margin.r = css.marginR;
  layout.margin.t = css.marginT;
  layout.margin.b = css.marginB;
  layout.height = css.height;
}

export function numberWithCommas(number) {
  const parts = number.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function getRanges(dataPieces, isExpanded) {
  let [min, max] = [Infinity, -Infinity];

  dataPieces.forEach((val) => {
    if (val < min) min = Number(val);
    if (val > max) max = Number(val);
  });

  const chartHeight = max - min;

  let minConverted;
  let maxConverted;

  if (chartHeight) {
    const rangeDivider = isExpanded ? 6 : 4;
    minConverted = Math.max(min - (chartHeight / rangeDivider), 0);
    maxConverted = max + (chartHeight / rangeDivider);
  } else {
    minConverted = min * 0.8;
    maxConverted = max * 1.2;
  }

  return {
    min: minConverted,
    max: maxConverted,
  };
}

export function getNumberFromPercentChange(resultNumber, percentChange) {
  return resultNumber / (1 + (percentChange / 100));
}

export function checkAndRoun(
  data, roundTo = 2, isPrefixNeeded = false, withSpaceBetween = true, allowDynamicRound = false,
) {
  const isNegativeNumber = Math.sign(data || 0) === -1;
  let roundToChk = roundTo;
  let dataChk = Math.abs(data || 0);
  let prefix = '';

  let divider = 1;
  if (isPrefixNeeded) {
    if (dataChk >= 1000000000000000) {
      divider = 1000000000000000;
      prefix = ' Q';
    } else if (dataChk >= 1000000000000) {
      divider = 1000000000000;
      prefix = ' T';
    } else if (dataChk >= 1000000000) {
      divider = 1000000000;
      prefix = ' B';
    } else if (dataChk < 1000000000 && dataChk >= 1000000) {
      divider = 1000000;
      prefix = ' M';
    } else if (dataChk < 1000000 && dataChk >= 1000) {
      divider = 1000;
      prefix = ' K';
    }
    dataChk /= divider;

    if (dataChk < 1 && !roundTo) roundToChk = 2;
  }

  if (isNegativeNumber) {
    dataChk = -dataChk;
  }

  if (!withSpaceBetween) {
    prefix = prefix.trim();
  }

  if (
    isPrefixNeeded
    && roundToChk === 0
    && (+dataChk).toFixed(roundToChk).length !== String(Math.trunc(+dataChk)).length
  ) {
    return checkAndRoun(
      Number((+dataChk).toFixed(roundToChk)) * divider,
      roundTo,
      isPrefixNeeded,
      withSpaceBetween,
    );
  }

  let allowZeroRound = false;
  if (allowDynamicRound && data < 1) {
    allowZeroRound = true;
  }

  return (
    numberWithCommas(
      isPrefixNeeded && !allowZeroRound
        ? Number((+dataChk).toFixed(roundToChk))
        : convertToDecimal(+dataChk),
    ) + prefix
  );
}

// Rounds Y axis data and adds current suffix
export function roundYAxisData(data) {
  if (!data) return { round: 1, suffix: '', data: [] };

  const arrMaxElem = Math.max(...data);
  const roundYAxisData = {
    round: 1,
    suffix: '',
  };

  if (arrMaxElem >= 1000000000) {
    roundYAxisData.round = 1000000000;
    roundYAxisData.suffix = 'B';
  } else if (arrMaxElem < 1000000000 && arrMaxElem > 1000000) {
    roundYAxisData.round = 1000000;
    roundYAxisData.suffix = 'M';
  } else if (arrMaxElem < 1000000 && arrMaxElem >= 1000) {
    roundYAxisData.round = 1000;
    roundYAxisData.suffix = 'K';
  }

  roundYAxisData.data = data.map((elem) => convertToDecimal(elem / roundYAxisData.round));

  return roundYAxisData;
}

// Returns ticks for graph
function getTicks(data, prefix = '', suffix = '', digits = 2) {
  let [min, max] = [Infinity, -Infinity];

  data.forEach((value) => {
    if (value > max) max = Number(value);
    if (value < min) min = Number(value);
  });

  const chartHeight = max - min;

  let avg = convertToDecimal((max + min) / 2, digits);
  max = convertToDecimal(max, digits);
  min = convertToDecimal(min, digits);

  if (!chartHeight) {
    avg = max;
    min = convertToDecimal(data[0] * 0.9, digits);
    max = convertToDecimal(data[0] * 1.1, digits);
  }

  return [min, avg, max].map((item) => (
    `${prefix}${numberWithCommas(item)}${suffix}`
  ));
}

export function preparePriceGraph(params) {
  const {
    title, timeFilter, stats, isExpanded, hasBigScreenDesign, showChartLabel,
    hasFourDecimal, chatbot, isChatbotOpened, expandedChatbot,
  } = params;
  let { width = null } = params;
  const isBig = hasBigScreenDesign && window.innerWidth >= 2800;
  const isMobile = (window.innerWidth < 768) || (chatbot && isChatbotOpened && !expandedChatbot);

  // calculating data for rendering
  const configs = {
    priceAndMarketCapRequest: false,
    graphData: {
      dateLabels: [],
      priceUsd: [],
      priceUsdVolume: [],
      marketCapUsd: [],
      comments: [],
    },
  };

  if (!width) width = window.innerWidth - 50;

  stats.forEach((stat) => {
    const date = moment(new Date(stat.last_updated));
    const dateComponent = date.utc().format('YYYY-MM-DD HH:mm');
    const comment = `${stat.comment ? `'<b>${stat.comment}</br></b>` : ''}`;

    configs.graphData.priceUsd.push(stat.price_usd);
    configs.graphData.comments.push(comment);

    configs.graphData.marketCapUsd.push(stat.market_cap_usd);
    configs.graphData.priceUsdVolume.push(stat.volume_usd_24h);
    configs.graphData.dateLabels.push(dateComponent);
  });

  let {
    priceUsd,
    marketCapUsd,
  } = configs.graphData;
  const {
    dateLabels,
    priceUsdVolume,
  } = configs.graphData;

  // generating graph data
  const yDataRounded = {
    dataPrice: {
      round: 1,
      suffix: '',
      data: priceUsd,
    },
    datamarketCap: roundYAxisData(marketCapUsd),
  };
  const annotations = [
    {
      xref: 'paper',
      yref: 'paper',
      x: 0,
      xshift: 4,
      xanchor: 'left',
      y: 1,
      yshift: -4,
      yanchor: 'top',
      text: title,
      showarrow: false,
      font: {
        family: 'Lato',
        size: isBig ? 28 : 14,
        color: '#000000',
      },
    },
  ];

  priceUsd = yDataRounded.dataPrice.data.map((elem) => numberWithCommas(
    convertToDecimal(elem, hasFourDecimal ? 4 : 2),
  ));
  marketCapUsd = yDataRounded.datamarketCap.data.map((elem) => (
    numberWithCommas(convertToDecimal(elem))
  ));

  const yVolumeDataRounded = roundYAxisData(priceUsdVolume);
  const dataArr = yDataRounded.dataPrice.data;
  let chartTypeColor;
  const firstPoint = dataArr[0];
  const lastPoint = dataArr[dataArr.length - 1];
  if (lastPoint > firstPoint) {
    chartTypeColor = '#55b667';
  } else if (firstPoint === lastPoint) {
    chartTypeColor = '#666666';
  } else {
    chartTypeColor = '#e35455';
  }
  const chartConfig = {
    type: 'scatter',
    mode: 'lines',
    // set numbers as x values to got linear x axis
    // and set actual values using tickers below with layout
    x: dateLabels.map((v, i) => i),
    name: 'main_line',
    marker: {
      symbol: '500',
    },
    fill: 'tozeroy',
    fillcolor: `${chartTypeColor}19`,
    fixedrange: true,
    line: {
      color: chartTypeColor,
      width: isBig ? 1.96 : 0.98,
      shape: 'spline',
    },
    hoverinfo: 'none',
    xaxis: 'x2',
    yaxis: 'y2',
  };

  const price = {
    ...chartConfig,
    y: priceUsd,
  };

  const marketCap = {
    ...chartConfig,
    y: marketCapUsd,
  };

  const isShortInterval = [ONE_DAY].includes(timeFilter);
  const dateTicks = dateLabels
    .map((v, i) => ({
      value: v,
      index: i,
    }))
    .filter(({ index: i }) => {
      let itemsCount;
      if ((width < 768) || isMobile) {
        itemsCount = 3;
      } else if (timeFilter === ONE_WEEK) {
        itemsCount = 5;
      } else {
        itemsCount = 8;
      }

      return (
        i !== 0 && (i % Math.floor(dateLabels.length / itemsCount)) === 0
      );
    });
  let layoutConfig = {
    fixedrange: true,
    autosize: true,
    dragmode: false,
    showlegend: false,
    hovermode: 'x2',
    paper_bgcolor: 'rgba(0, 0, 0, 0)',
    plot_bgcolor: 'rgba(255, 255, 255)',
    bargap: isBig ? 1 : 0.5,
    annotations: showChartLabel ? annotations : null,
    font: {
      size: isBig ? 26 : 13,
      family: 'Lato',
    },
    height: css.height,
    margin: {
      l: css.marginL,
      r: css.marginR,
      t: css.marginT,
      b: css.marginB,
      pad: '0', // distance between axis and graph
    },
    xaxis: {
      type: '-',
      showgrid: true,
      fixedrange: true,
      showline: true,
      zeroline: false,
      mirror: true,
      tickfont: {
        size: isBig ? '32px' : '16px',
        family: 'Lato',
        color: 'rgb(41, 41, 41)',
      },
      tickvals: dateTicks.map(({ index }) => index),
      ticktext: dateTicks.map(({ value }, i) => {
        let format = 'DD MMM<br />YYYY';

        if (isShortInterval) {
          if (
            i !== 0
            && moment(dateTicks[i - 1].value).format(format) === moment(value).format(format)
          ) {
            format = 'HH:mm';
          } else if (
            i < dateTicks.length - 1
            && moment(dateTicks[i + 1].value).format(format) === moment(value).format(format)
          ) {
            format = 'HH:mm<br />DD MMM YYYY';
          }
        }

        return moment(value).format(format);
      }),
      automargin: false,
      tickangle: 0,
      showticklabels: true,
      linewidth: 2,
      linecolor: '#eeeeee',
      anchor: 'y',
    },
    yaxis: {
      side: 'right',
      rangeselector: {
        visible: false,
      },
      ticklabelposition: isMobile ? 'inside' : 'outside',
      fixedrange: true,
      showgrid: true,
      zeroline: false,
      showline: true,
      mirror: true,
      tickmode: 'array',
      nticks: 4,
      separatethousands: true,
      linewidth: 2,
      linecolor: '#eeeeee',
      type: 'linear',
      tickfont: {
        size: isBig ? '26px' : '13px',
        family: 'Lato',
        color: '#292929',
      },
      exponentformat: 'none',
    },
  };

  responsiveCSS(layoutConfig, hasBigScreenDesign, isMobile);

  if (isExpanded) {
    layoutConfig = LodashOmit(layoutConfig, 'height');
  }

  function getLayoutConfig(source) {
    const { min, max } = getRanges(source.data, isExpanded);

    return {
      ...layoutConfig,
      xaxis: {
        ...layoutConfig.xaxis,
        showgrid: false,
        ...(isShortInterval && {
          title: {
            text: 'GMT',
            standoff: 8,
            font: {
              family: 'Lato',
              size: isBig ? 28 : 14,
            },
          },
        }),
        range: [0, dateLabels.length - 1],
      },
      yaxis: {
        ...layoutConfig.yaxis,
        showgrid: false,
        autorange: true,
        zeroline: true,
        showticklabels: false,
        ticksuffix: yVolumeDataRounded.suffix,
      },
      xaxis2: {
        ...layoutConfig.xaxis,
        zeroline: true,
        zerolinecolor: '#c0c0c0',
        showticklabels: false,
        range: [0, dateLabels.length - 1],
      },
      yaxis2: {
        ...layoutConfig.yaxis,
        tickvals: getTicks(source.data, '', '', hasFourDecimal ? 4 : 2),
        ticktext: getTicks(source.data, '', source.suffix, hasFourDecimal ? 4 : 2),
        range: [min, max],
        ticksuffix: source.suffix,
      },
    };
  }

  const priceLayout = getLayoutConfig(yDataRounded.dataPrice);
  const marketCapLayout = getLayoutConfig(yDataRounded.datamarketCap);

  return {
    priceData: price,
    marketCapData: marketCap,
    priceLayout,
    marketCapLayout,
    chartTypeColor,
  };
}

export function getStartDate(timeFilter) {
  const date = new Date();

  switch (timeFilter) {
    case '1h':
      date.setHours(date.getHours() - 1);
      break;
    case '1d':
      date.setDate(date.getDate() - 1);
      break;
    case '1w':
      date.setDate(date.getDate() - 7);
      break;
    case '1m':
      date.setMonth(date.getMonth() - 1);
      break;
    case '3m':
      date.setMonth(date.getMonth() - 3);
      break;
    case '1y':
      date.setFullYear(date.getFullYear() - 1);
      break;
    default:
    case 'All':
      date.setTime(1000);
      break;
  }

  return date;
}

export function getStartDateDays(timeFilter) {
  switch (timeFilter) {
    default:
    case '1d':
      return 1;
    case '1w':
      return 7;
    case '1m':
      return 30;
  }
}

export function prepareMarketShareGraph(data, timeFilter, containerWidth = null) {
  // calculating data for rendering
  const width = containerWidth || window.innerWidth - 50;

  const configs = {
    graphData: {},
  };
  const [newObject, dataNormalized] = [{}, {}];
  const timePeriod = timeInterval[timeFilter].marketShareXInterval.split(' ');

  configs.graphData.sortArray = [];
  configs.graphData.legendSortArr = Object.keys(data);

  Object.entries(data).forEach(([cryptocurrency, cryptocurrencyList]) => {
    let summaryMarketCap = 0;

    cryptocurrencyList.forEach((item) => {
      const currentTime = cryptocurrency === 'others' && timeFilter === 'All'
        ? moment(item.created_at).add(12, 'h').round(timePeriod[0], timePeriod[1]).format()
        : moment(item.created_at).round(timePeriod[0], timePeriod[1]).format();

      newObject[currentTime] = newObject[currentTime] || {};
      newObject[currentTime][cryptocurrency] = item;
      summaryMarketCap += item.market_cap_usd;
    });

    configs.graphData.sortArray.push({
      average: summaryMarketCap / (cryptocurrencyList.length + 1),
      cryptocurrency,
    });
  });

  configs.graphData.sortArray.reverse();

  Object.entries(newObject).forEach(([date, item]) => {
    let sumVal = 0;

    configs.graphData.sortArray.forEach((cyprtoItem) => {
      const { cryptocurrency } = cyprtoItem;
      if (cryptocurrency !== 'others') {
        if (!item[cryptocurrency]) return;

        sumVal += newObject[date][cryptocurrency].market_cap_usd;
        dataNormalized[cryptocurrency] = dataNormalized[cryptocurrency] || [];

        dataNormalized[cryptocurrency].push({
          date,
          converted_market_cap_usd: sumVal,
          data: item[cryptocurrency],
        });
      }
    });
  });

  Object.values(dataNormalized).forEach((value) => (
    value.sort((a, b) => (new Date(a.date)).getTime() - (new Date(b.date)).getTime())
  ));

  configs.graphData.sortArray = Object.keys(dataNormalized);
  configs.graphData.marketShareData = dataNormalized;
  configs.marketShareUpdateTime = moment();

  // generating graph data

  const { marketShareData } = configs.graphData;
  // --------------------------------------------------

  const dataArr = [];
  const legendObj = {};
  const { sortArray } = configs.graphData;

  let counter = 0;
  sortArray.reverse().forEach((cryptocurrency) => {
    const [axisX, toolTips, axisY] = [[], [], []];
    const { lineColor, backgroundColor } = graphColors[counter];

    if (!marketShareData[cryptocurrency]) return;

    Object.values(marketShareData[cryptocurrency]).forEach((value) => {
      axisY.push((value.converted_market_cap_usd / 1000000000).toFixed(2));
      axisX.push(value.date);
      toolTips.push(
        `$${checkAndRoun(value.data.market_cap_usd, 2, true)}
        <br />${value.data.name.replace(/\b\w/g, (l) => l.toUpperCase())}`,
      );
    });

    const graph = {
      type: 'scatter',
      mode: 'lines',
      stackgroup: 'one',
      x: axisX,
      y: axisY,
      text: toolTips,
      name: marketShareData[cryptocurrency][0].data.name.replace(/\b\w/g, (l) => l.toUpperCase()),
      hoverinfo: 'x+text',
      paper_bgcolor: 'rgba(0, 0, 0, 0)',
      plot_bgcolor: 'rgba(0, 0, 0, 0)',
      marker: {
        symbol: '500',
      },
      fillcolor: `rgba(${backgroundColor}, 1)`,
      fixedrange: true,
      line: {
        color: `rgb(${lineColor})`,
        width: 1.5,
        shape: 'spline',
      },
      hoverlabel: {
        bgcolor: '#414e4c',
        bordercolor: '#414e4c',
        font: {
          family: 'Lato',
          size: '13px',
          color: '#fff',
        },
      },
    };

    dataArr.push(graph);
    legendObj[cryptocurrency] = {
      color: `rgb(${lineColor})`,
      name: marketShareData[cryptocurrency][0].data.name,
    };

    counter += 1;
    if (counter === graphColors.length) counter = 0;
  });

  const layout = {
    fixedrange: true,
    autosize: true,
    showlegend: false,
    hovermode: 'x',
    paper_bgcolor: 'rgba(0, 0, 0, 0)',
    plot_bgcolor: 'rgba(255, 255, 255)',
    font: {
      size: '12px',
      family: 'Lato',
    },
    width: width || window.innerWidth - 50,
    margin: {
      /*
        Market share shows y-axis data on the left side but the other chart shows the y-axis
        on the right side. To add the space on the left side switch the calculated margin
        laft(Left represent calculated right) and right(Right represent calculated left).
      */
      l: css.marginR,
      r: css.marginL,
      t: css.marginT,
      b: css.marginB,
      pad: '0',
    },
    xaxis: {
      type: 'date',
      showgrid: false,
      fixedrange: true,
      autorange: true,
      showline: true,
      linewidth: 2,
      zeroline: true,
      autotick: true,
      anchor: 'y',
    },
    yaxis: {
      rangeselector: {
        visible: false,
      },
      autotick: true,
      fixedrange: true,
      showgrid: false,
      autorange: true,
      zeroline: true,
      zerolinecolor: '#eeeeee',
      showline: true,
      separatethousands: true,
      type: '-',
      tickfont: {
        size: '12px',
        family: 'Lato',
        color: '#292929',
      },
      ticksuffix: ' B',
    },
  };

  if (!['1h', '1d'].includes(timeFilter)) layout.xaxis.tickformat = '%d %b<br />%Y';

  responsiveCSS(layout);

  return {
    marketShares: dataArr,
    layout,
    legend: legendObj,
  };
}

export function addPathOnBreadcrumbs(breadcrumbs, path) {
  return breadcrumbs.map((breadcrumb) => ({
    ...breadcrumb,
    path: `${path}/${breadcrumb.path}`,
  }));
}

export function removeSlugFromPath(path) {
  const pieces = path.split('/').reverse();

  if (!pieces[0]) {
    pieces.shift();
  }
  if (['all', 'very_positive', 'positive', 'neutral', 'negative', 'very_negative'].includes(pieces[0])) {
    if (path.includes(`/news/${pieces[0]}`)) {
      pieces.splice(0, 2);
    } else {
      pieces.shift();
    }
  } else {
    pieces.shift();
  }

  return pieces.reverse().join('/');
}

export function removeSentimentFromPath(path) {
  const pieces = path.split('/').reverse();

  if (['all', 'very_positive', 'positive', 'neutral', 'negative', 'very_negative'].includes(pieces[0])) {
    pieces.shift();
  }

  return pieces.reverse().join('/');
}

export function removeSentimentAndSlugFromPath(path, slug) {
  const pieces = path.split('/').reverse();

  if (['all', 'very_positive', 'positive', 'neutral', 'negative', 'very_negative'].includes(pieces[0])) {
    if (path.includes(`/${slug}/${pieces[0]}`)) {
      pieces.splice(0, 2);
    }
  } else {
    pieces.shift();
  }

  return pieces.reverse().join('/');
}

export function getFlag(topic) {
  if (topic.short_name in flags) return flags[topic.short_name];
  if (topic?.name?.toLowerCase() in flags) return flags[topic.name.toLowerCase()];
  return null;
}

export const getCorrectMarginRight = ({ chartMaxValue, state, screenWidth }) => {
  let copiedNumberToString = '';
  let numberOfCharacters = 0;
  if (typeof chartMaxValue === 'number') copiedNumberToString = chartMaxValue.toString();
  const [integerPart, decimalPart] = copiedNumberToString.split('.');

  if (decimalPart) {
    numberOfCharacters = integerPart.length;
  } else {
    numberOfCharacters = copiedNumberToString.length;
  }

  if ((numberOfCharacters > 3) && state.layout
    && (screenWidth > 768)) {
    let newMargin = Number(state.layout.margin.r) || 70;

    const startIndex = screenWidth > 1024 ? 4 : 2;
    for (let i = startIndex; i <= numberOfCharacters; i += 1) {
      newMargin += 6;
    }
    state.layout.margin.r = newMargin?.toString();
  }
  return { state };
};
