import { Component, createRef } from 'react';
import classNames from 'classnames/bind';
import autoBind from 'react-autobind';
import { isDesktop, isIOS, isIOS13 } from 'react-device-detect';
import Tooltip from '../../../../Tooltip';

import withComponentName from '../../../../../decorators/withComponentName';
import { uuid } from '../../../../../utils';

import Styles from './styles.module.scss';
import getUserToken from '../../../../../helpers/getUserToken';
import PlayVideoComponent from '../../../../PlayVideoComponent';

const cx = classNames.bind(Styles);

export class SwitchButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkStatus: false,
    };
    autoBind(this);
    this.defaultId = uuid();
    this.switchInput = createRef();
  }

  componentDidMount() {
    const { status } = this.props;

    this.setState({
      checkStatus: status || false,
    });
  }

  componentDidUpdate(prevProps) {
    const { status, goldUser, onlyGold } = this.props;

    if (onlyGold) {
      if (status !== prevProps.status && goldUser) {
        this.setState({
          checkStatus: status,
        });
      }
    } else if (status !== prevProps.status) {
      this.setState({
        checkStatus: status,
      });
    }
  }

  toggleCheck() {
    const { checkAuth, onChange } = this.props;
    const { checkStatus } = this.state;

    if (checkAuth && !getUserToken()) {
      if (onChange) {
        onChange(checkStatus);
        this.setState(() => ({
          checkStatus: false,
        }));
      }
    } else {
      this.setState(() => ({
        checkStatus: this.switchInput.current.checked,
      }), () => {
        const { checkStatus } = this.state;

        if (onChange) onChange(checkStatus);
      });
    }
  }

  render() {
    const {
      rbi,
      id = this.defaultId,
      className,
      title,
      disabled,
      hasBigScreenDesign,
      onboarding,
    } = this.props;
    const { checkStatus } = this.state;

    return (
      <div className={cx('other-filters__item',
        { disabled, big_screen: hasBigScreenDesign },
        { onboarding })}
      >
        {title === 'Group Similar Stories' ? (
          <div className={cx('other-tooltip-wrapper')}>
            {title}
            <Tooltip className={cx('stories-group-tooltip', { ios: isIOS || isIOS13 })}>
              AI chooses the most representative story from a group of similar content
            </Tooltip>
            {
            !isDesktop && (
              <PlayVideoComponent
                videoLinkMobile="https://cityfalcon.wistia.com/medias/ktxo5yh7gp"
                additionalStyles={cx('other_filters_play_button')}
              />
            )
          }
          </div>
        ) : (
          <span>
            {title}
          </span>
        )}
        <div className={`${cx('switch_button')} ${className}`}>
          <input
            type="checkbox"
            id={id}
            name={id}
            checked={checkStatus}
            ref={this.switchInput}
            onChange={this.toggleCheck}
          />
          <div className={Styles.toggle}>
            <label htmlFor={id}>
              <i />
            </label>
          </div>
        </div>
      </div>
    );
  }
}

SwitchButton.defaultProps = {
  className: '',
  goldUser: false,
  onlyGold: false,
};

export default withComponentName(SwitchButton);
