export function convertToDecimal(num, digits = 2) {
  if (!num) return '0.00';

  if (
    Math.trunc(Number(num)) === Number(num)
    || Math.trunc(Number(num)) > 0
    || String(num) === Number(num).toFixed(digits)
    || (
      Number(num).toFixed(digits) > 0
      && !String(num).split('.').pop().startsWith('0')
    )
  ) {
    return Number(Number(num).toFixed(digits));
  }

  let numConverted = String(num);
  if (/e/i.test(numConverted)) numConverted = Number(num).toFixed(20);

  const [, base, decimals] = numConverted.match(/^(-?\d+\.0*)(\d+)/);

  const fixedDecimals = (
    Number(Number(`0.${decimals}`).toFixed(digits)) * (10 ** digits)
  ).toString().slice(0, digits);

  return base + fixedDecimals;
}

export default convertToDecimal;
