import {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  Children,
} from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import ResizeObserver from 'resize-observer-polyfill';

import { NextArrow, PrevArrow } from './ScrollbarControls';

import usePrevious from '../../../hooks/usePrevious';

import { elementInViewStats } from '../../../helpers/helpers';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

function scrollIntoView({
  scrollContainerRef,
  child,
}) {
  scrollContainerRef.current.scrollTo({
    top: 0,
    left: (
      scrollContainerRef.current.scrollLeft
      + child.getBoundingClientRect().left
      - Math.abs(
        scrollContainerRef.current.clientWidth
        - child.clientWidth,
      ) / 2
    ),
    behavior: 'smooth',
  });
}

export const Scrollable = (props) => {
  const {
    children,
    disabled = false,
    className = '',
    wrapperClassName = '',
    arrowStyle = 'default',
    arrowClass = '',
    hideArrows = false,
    carousel = false,
    carouselItemClassName = '',
    isAllowFull,
    hasDelayScroll = false,
    allowAutoScroll = false,
    getActiveChildIndex,
    isActive,
    chatbotWidget,
    arrowAndContentAlignment,
    activeIndexSelectedItem,
    saveIndexTopicChangedPosition,
    skipArrowWidth,
    scrollSkip = 0,
    scrollAtEnd,
  } = props;
  const [leftBtnVisible, setLeftBtnVisible] = useState(false);
  const [rightBtnVisible, setRightBtnVisible] = useState(false);

  const scrollContainerRef = useRef(null);
  const scrollWrapperRef = useRef(null);
  const activeElementIndexRef = useRef(0);
  const childrenRefs = useRef(Array(Children.count(children)));
  const insideArrowRef = useRef(false);
  const rightBtnVisibleRef = useRef(false);
  const autoScrollDisableRef = useRef(false);
  let autoScrollInterval = 0;
  let isScrollingInterval = 0;
  const isScrollingRef = useRef(false);
  const scrollValue = useRef(0);
  const scrollAtEndRef = useRef(false);

  const width = useSelector(({ common }) => (
    common.width
  ));

  const waitForScrollEnd = () => {
    let lastChangedFrame = 0;
    let lastX = scrollContainerRef.current.scrollX;
    let lastY = scrollContainerRef.current.scrollY;
    return new Promise((resolve) => {
      function tick(frames) {
        // We requestAnimationFrame either for 500 frames or until 20 frames with
        // no change have been observed.
        if (!isScrollingRef.current && (frames >= 500 || frames - lastChangedFrame > 20)) {
          resolve();
        } else {
          if (
            scrollContainerRef.current.scrollX !== lastX
            || scrollContainerRef.current.scrollY !== lastY
          ) {
            lastChangedFrame = frames;
            lastX = scrollContainerRef.current.scrollX;
            lastY = scrollContainerRef.current.scrollY;
          }
          requestAnimationFrame(tick.bind(null, frames + 1));
        }
      }
      tick(0);
    });
  };

  const onHorizontalScroll = useCallback(() => {
    const { scrollLeft, clientWidth, scrollWidth } = scrollContainerRef.current || {};
    setLeftBtnVisible(scrollLeft > 0);
    setRightBtnVisible(Math.ceil(scrollLeft + clientWidth) < scrollWidth);
    clearTimeout(isScrollingInterval);
    isScrollingRef.current = true;
    // Set a timeout to run after scrolling ends
    isScrollingInterval = setTimeout(() => {
      // Run the callback
      isScrollingRef.current = false;
    }, 100);
  }, [scrollContainerRef]);

  useEffect(() => {
    childrenRefs.current = childrenRefs.current.slice(0, Children.count(children));
  }, [children]);

  useEffect(() => {
    if (!scrollWrapperRef.current || disabled) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      onHorizontalScroll();
    });

    resizeObserver.observe(scrollWrapperRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [onHorizontalScroll, disabled]);

  const passActiveIndex = (index) => {
    if (getActiveChildIndex) {
      getActiveChildIndex(index);
    }
  };

  const onTouchEnd = async () => {
    if ((!carousel && !getActiveChildIndex)) {
      return;
    }

    await waitForScrollEnd();

    Promise.all(
      childrenRefs.current.map((element) => (
        elementInViewStats(element)
      )),
    ).then((stats) => {
      let mostlyInViewIndex = -1;
      let mostlyInViewVisibilityPercent = 0;

      stats.forEach((stat, index) => {
        if (!stat.visible) {
          return;
        }

        if (
          mostlyInViewVisibilityPercent <= stat.visibilityPercent
        ) {
          mostlyInViewVisibilityPercent = stat.visibilityPercent;
          mostlyInViewIndex = index;
        }
      });
      activeElementIndexRef.current = mostlyInViewIndex;
      passActiveIndex(mostlyInViewIndex);
      if (carousel && childrenRefs.current[mostlyInViewIndex]) {
        scrollIntoView({
          child: childrenRefs.current[mostlyInViewIndex],
          scrollContainerRef,
        });
      }
    });
  };

  const delayScroll = (start, end, duration) => {
    const delta = end - start;
    let startTime;
    if (window.performance && window.performance.now) {
      startTime = performance.now();
    } else if (Date.now) {
      startTime = Date.now();
    } else {
      startTime = new Date().getTime();
    }

    const easing = (x, t, b, c, d) => {
      if ((t /= d / 2) < 1) return c / 2 * t * t + b;
      return -c / 2 * ((--t) * (t - 2) - 1) + b;
    };

    const delayScrollLoop = (time) => {
      const t = (!time ? 0 : time - startTime);
      const factor = easing(null, t, 0, 1, duration);
      if (!scrollContainerRef.current) return;

      scrollContainerRef.current.scrollLeft = start + delta * factor;
      if (t < duration && scrollContainerRef.current.scrollLeft !== end) {
        requestAnimationFrame(delayScrollLoop);
      } else {
        // eslint-disable-next-line no-use-before-define
        autoScrollRightSide();
      }
    };
    delayScrollLoop();
  };

  const getArrowSpace = () => {
    if (width >= 2800) {
      return 77;
    }
    if (width >= 1024) {
      return 40;
    }
    return 0;
  };

  const scroll = (direction, autoScroll) => {
    const { scrollLeft, clientWidth } = scrollContainerRef.current;
    if (!autoScroll) autoScrollDisableRef.current = true;
    switch (direction) {
      case -1: {
        if (!leftBtnVisible) break;
        scrollValue.current = 0;
        if (isAllowFull) {
          Promise.all(
            childrenRefs.current.map((element) => (
              elementInViewStats(element)
            )),
          ).then((stats) => {
            const firstActiveIndex = stats.findIndex((f) => f.visible);
            const activeChild = stats[firstActiveIndex];
            const currentHidden = activeChild?.extra?.intersectionRect?.width
              < activeChild?.extra?.boundingClientRect?.width;
            const widthMoving = clientWidth
              - (currentHidden ? (activeChild?.extra?.intersectionRect?.width || 0) : 0);

            let leftDistance = Math.max(
              childrenRefs.current[firstActiveIndex].offsetLeft - widthMoving, 0,
            );
            if (chatbotWidget) {
              leftDistance = childrenRefs.current[firstActiveIndex].offsetLeft - (widthMoving / 2);
            }
            passActiveIndex(firstActiveIndex);
            if (hasDelayScroll) {
              delayScroll(scrollLeft, leftDistance, 2000);
            } else {
              scrollContainerRef.current.scrollTo({
                top: 0,
                left: leftDistance,
                behavior: 'smooth',
              });
              // eslint-disable-next-line no-use-before-define
              autoScrollRightSide();
            }
          });
        } else if (carousel) {
          const index = Math.max(activeElementIndexRef.current - 1, 0);
          scrollIntoView({
            child: childrenRefs.current[index],
            scrollContainerRef,
          });
          activeElementIndexRef.current = index;
          passActiveIndex(index);
        } else {
          scrollContainerRef.current.scrollTo({
            top: 0,
            left: scrollLeft - clientWidth + scrollSkip,
            behavior: 'smooth',
          });
        }
        break;
      }
      case 1: {
        if (!rightBtnVisible) break;
        if (isAllowFull) {
          Promise.all(
            childrenRefs.current.map((element) => (
              elementInViewStats(element)
            )),
          ).then((stats) => {
            let currentIndex = 0;
            stats
              .forEach((stat, index) => {
                if (!stat.visible) {
                  return;
                }
                if (
                  stat?.extra?.intersectionRect?.width <= stat?.extra?.boundingClientRect?.width
                ) {
                  currentIndex = index;
                }
              });

            const arrowSpace = getArrowSpace();
            const containerRefWidth = scrollContainerRef?.current.offsetWidth;
            const currentChildWidth = childrenRefs.current[currentIndex].offsetWidth;

            let leftDistance = childrenRefs.current[currentIndex].offsetLeft - arrowSpace;
            if (chatbotWidget) {
              if ((leftDistance !== scrollValue.current) && (scrollValue.current < leftDistance)) {
                scrollValue.current = leftDistance;
              } else if (scrollValue.current > leftDistance) {
                leftDistance = scrollValue.current + currentChildWidth / 2;
                scrollValue.current = leftDistance;
              } else if (currentChildWidth >= (containerRefWidth - arrowSpace) * 2) {
                leftDistance += currentChildWidth / 2;
                scrollValue.current = leftDistance;
              } else if (currentChildWidth >= (containerRefWidth - arrowSpace)) {
                leftDistance += (currentChildWidth / 4);
                scrollValue.current = leftDistance;
              }
            }
            passActiveIndex(currentIndex);
            if (hasDelayScroll) {
              delayScroll(scrollLeft, leftDistance, 2000);
            } else {
              scrollContainerRef.current.scrollTo({
                top: 0,
                left: leftDistance,
                behavior: 'smooth',
              });
              // eslint-disable-next-line no-use-before-define
              autoScrollRightSide();
            }
          });
        } else if (carousel) {
          const index = Math.min(activeElementIndexRef.current + 1, Children.count(children) - 1);
          scrollIntoView({
            child: childrenRefs.current[index],
            scrollContainerRef,
          });
          activeElementIndexRef.current = index;
          passActiveIndex(index);
        } else {
          const arrowSpace = skipArrowWidth ? getArrowSpace() : 0;
          scrollContainerRef.current.scrollTo({
            top: 0,
            left: scrollLeft + clientWidth - arrowSpace - scrollSkip,
            behavior: 'smooth',
          });
        }
        break;
      }
      default:
        break;
    }
  };

  const timeOutInnerScroll = useCallback(() => {
    if (
      !allowAutoScroll || !rightBtnVisibleRef.current || insideArrowRef.current
    ) {
      return;
    }
    scroll(1, true);
  }, [allowAutoScroll, rightBtnVisible]);

  const autoScrollRightSide = useCallback(() => {
    clearTimeout(autoScrollInterval);
    if (
      !allowAutoScroll || !rightBtnVisibleRef.current || insideArrowRef.current
      || autoScrollDisableRef.current
    ) {
      return;
    }
    autoScrollInterval = setTimeout(() => {
      timeOutInnerScroll();
    }, width < 768 ? 5000 : 7000);
  }, [allowAutoScroll, rightBtnVisible, width]);

  const mouseEnter = () => {
    insideArrowRef.current = true;
    clearTimeout(autoScrollInterval);
  };
  const mouseLeave = async (e) => {
    e.persist();
    if (e.type === 'touchend') {
      autoScrollDisableRef.current = true;
      await waitForScrollEnd();
    }
    insideArrowRef.current = false;
    autoScrollRightSide();
  };

  // Method to ensure the selected element is fully visible
  const scrollToElementIfNeeded = (elementRef, activeIndexSelectedItem) => {
    if (elementRef && scrollContainerRef.current) {
      const container = scrollContainerRef.current;

      const containerRect = container.getBoundingClientRect();
      const elementRect = elementRef.getBoundingClientRect();

      // Check if element is not fully visible
      if (((elementRect.left - 50) < containerRect.left)
      || ((elementRect.right + 50) > containerRect.right)) {
        const newScrollPosition = elementRef.offsetLeft + (elementRef.offsetWidth / 2)
        - (container.offsetWidth / 2);

        container.scrollTo({
          left: newScrollPosition,
          behavior: 'smooth',
        });

        saveIndexTopicChangedPosition(activeIndexSelectedItem);
      }
    }
  };

  useEffect(() => {
    scrollToElementIfNeeded(
      childrenRefs?.current[activeIndexSelectedItem], activeIndexSelectedItem,
    );
  }, [activeIndexSelectedItem]);

  useEffect(() => {
    rightBtnVisibleRef.current = rightBtnVisible;
    autoScrollRightSide();
    if (scrollAtEnd && !scrollAtEndRef.current) {
      scrollAtEndRef.current = true;
      const { offsetWidth } = scrollContainerRef.current;
      scrollContainerRef.current.scrollTo({
        top: 0,
        left: offsetWidth,
        behavior: 'smooth',
      });
    }
  }, [rightBtnVisible]);

  const prevAllowAutoScroll = usePrevious(allowAutoScroll);
  useEffect(() => {
    if (
      allowAutoScroll !== prevAllowAutoScroll
    ) {
      if (allowAutoScroll && !autoScrollDisableRef.current) {
        autoScrollRightSide();
      } else {
        clearTimeout(autoScrollInterval);
      }
    }

    return (() => {
      clearTimeout(autoScrollInterval);
    });
  }, [allowAutoScroll, rightBtnVisible]);

  const mainEvents = allowAutoScroll ? {
    onTouchStart: mouseEnter,
    onTouchEnd: mouseLeave,
    onMouseEnter: mouseEnter,
    onMouseLeave: mouseLeave,
  } : {};

  return (
    <div
      className={cx('scrollable', className, { disabled }, { active: isActive },
        { content_in_one_line: arrowAndContentAlignment })}
      {...mainEvents}
    >
      {!hideArrows && leftBtnVisible && (
        <PrevArrow arrowStyle={arrowStyle} arrowClass={arrowClass} onClick={() => scroll(-1)} />
      )}
      <div
        ref={scrollContainerRef}
        onScroll={onHorizontalScroll}
        className={cx('scrollable-container')}
        onTouchEnd={onTouchEnd}
      >
        <div
          ref={scrollWrapperRef}
          className={cx('scrollable-wrapper', wrapperClassName)}
        >
          {!disabled && (carousel || isAllowFull) ? (
            Children.map(children, (child, index) => (
              <div
                ref={(el) => { childrenRefs.current[index] = el; }}
                className={carouselItemClassName}
              >
                {child}
              </div>
            ))
          ) : (
            children
          )}
        </div>
      </div>
      {!hideArrows && rightBtnVisible && (
        <NextArrow arrowStyle={arrowStyle} arrowClass={arrowClass} onClick={() => scroll(1)} />
      )}
    </div>
  );
};

export default memo(Scrollable);
