import { Component, createRef } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEqual from 'lodash-es/isEqual';
import debounce from 'lodash-es/debounce';
import autoBind from 'react-autobind';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faCreditCardFront } from '@fortawesome/pro-light-svg-icons/faCreditCardFront';
import { faIdCard } from '@fortawesome/pro-light-svg-icons/faIdCard';
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment';
import { faRetweet } from '@fortawesome/pro-regular-svg-icons/faRetweet';
import { faHeart } from '@fortawesome/pro-regular-svg-icons/faHeart';
import { faAngleUp } from '@fortawesome/pro-regular-svg-icons/faAngleUp';
import { faLanguage as faLanguageRegular } from '@fortawesome/pro-regular-svg-icons/faLanguage';
import { faLanguage as faLanguageLight } from '@fortawesome/pro-light-svg-icons/faLanguage';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';

import axios from 'axios';
import * as storyFiltersActions from '../../../actions/storyFilters.action';
import * as storyActions from '../../../actions/stories.actions';
import * as authentication from '../../../actions/authentication.actions';

import StoryCardFooter from './StoryCardFooter';
import TwitterImgSliderModal from './TwitterImgSliderModal';
import SimilarStories from './SimilarStories';
import CompactViewImageLoader from './CompactViewImageLoader';
import TilesView from './TilesView';
import Tooltip from '../../Tooltip';
import StoriesUpgradeSuggestion from '../../SubscriptionsComponents/StoriesUpgradeSuggestion';
import PremiumLabelHideModal from '../../Modals/PremiumLabelHideModal';
import Scrollable from '../../Shared/Scrollable';
import SentimentTag from '../../SEO_pages/TopicsSentimentAnalysis/SentimentTags/SentimentTag';
import SentimentModal from '../../SEO_pages/TopicsSentimentAnalysis/SentimentTags/SentimentModal';

import BookmarkStoryIcon from '../../BookmarksComponent/BookmarkStoryIcon';

import RBILogo from '../../../assets/images/clients/rbi/rbi-logo.png';
import defaultStoryImg from '../../../../../assets/images/author_default_logo.png';

import PiwikContext from '../../../services/piwikContext';
import StoriesSvc from '../../../services/StoriesSvc';
import API from '../../../services/dbServices/api';
import StoriesApi from '../../../services/dbServices/StoriesApiService';
import EntitiesSvc from '../../../services/dbServices/EntitiesSvc';
import SectorsSvc from '../../../services/SectorsSvc';
import TopicsSvc from '../../../services/TopicsSvc';
import StorageSvc from '../../../services/StorageSvc';

import {
  calculateTimeAgo,
  returnCDNFoxyImageUrl,
  sortAndOrderInfoLabelItems,
} from '../../../helpers/helpers';
import { getAverageSentimentStoryText } from '../../../helpers/sentimentHelpers';
import { getTrendingBarAssetQuery } from '../../../helpers/watchlistHelperFunctions';
import convertPermalinkToPath from '../../../helpers/urlHelpers';
import loadable from '../../../utils/loadable';

import { GOLD } from '../../../data/permissions';
import { RBI } from '../../../data/clients';
import { GOLD_PLAN, SILVER_PLAN } from '../../../data/subscriptionPlans';
import { CATEGORY_TOPIC_CLASS, TOPIC_CLASS } from '../../../data/directory/topicTypes';
import { entityType } from '../../../data/sentimentAnalysis';
import { SECTORS_PATH_ITEM } from '../../../data/directory/constants';
import { CF_URL_FOR_FINPROMPT } from '../../../data/environment';

import withFinprompt from '../../../decorators/withFinprompt';
import withComponentName from '../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const SimilarStoriesHideModal = loadable(() => import('../../Modals/SimilarStoriesHideModal'));

const cx = classNames.bind(Styles);

export class StoryCard extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      expanded: false,
      storyImage: defaultStoryImg,
      showScorePopover: false,
      showPaywallPopover: false,
      showRegisterPopover: false,
      twitterImgLoaded: false,
      isTranslationShown: false,
      translation: {
        title: '',
        description: '',
      },
      temporaryTranslationMessage: false,
      similarStories: {
        stories: [],
        loading: true,
        allCount: 0,
      },
      storiesHideModalVisible: false,
      isToolTipTop: true,
      span: 5,
      isFoxy: true,
      imageLoadingError: false,
      domainLoadingError: false,
      domainImageTypeFirst: true,
      showHideLabelModal: false,
      removedBookmark: false,
      selectedTopic: null,
      currentIcon: faLanguageLight,
    };

    this.mounted = true;
    this.twitterImgSliderModal = null;
    this.cardDiv = createRef();
    this.imageRef = createRef();
    this.descRef = createRef();
    this.titleRef = createRef();
    this.sentimentContentRef = createRef();
    this.popUpTimer = 0;
    this.entityLabelRefs = [];
    this.tooltipContentRefs = [];
    this.cancelToken = axios.CancelToken;
    this.expandRowViewButtonRef = createRef();
    this.expandCompactViewButtonRef = createRef();
    this.sourceCancelToken = axios.CancelToken.source();
  }

  componentDidMount() {
    const {
      expandFilter, showTranslation, popup, story,
    } = this.props;

    if (!popup) {
      this.toggleStoryExpandState(expandFilter);
    } else {
      this.toggleStoryExpandState(false);
    }

    const { type, icon } = getAverageSentimentStoryText(story.sentiment);
    this.setState({
      isTranslationShown: showTranslation,
      sentimentValue: (story.sentiment || story.sentiment === 0) && { type, icon },
    }, () => {
      this.setTranslations();
      this.loadStoryImage();
    });
    this.fetchSimilarStories();
    window.addEventListener('scroll', this.onScroll, { passive: true });
  }

  componentDidUpdate(prevProps) {
    const {
      story,
      showTranslation,
      expandFilter,
      scrollDivTop,
      watchlistPage,
      storyFilters,
    } = this.props;

    if (story.author_image_url !== prevProps.story.author_image_url) {
      this.loadStoryImage();
    }
    if (showTranslation !== prevProps.showTranslation) {
      this.setState({ isTranslationShown: showTranslation });
      this.setTranslations();
    } else if (!isEqual(prevProps.story.translations, story.translations)) {
      this.setTranslations();
    }

    if (prevProps.expandFilter !== expandFilter) {
      this.toggleStoryExpandState(expandFilter);
    }
    if (prevProps.story !== story) {
      this.fetchSimilarStories();
    }
    if ((scrollDivTop !== prevProps.scrollDivTop && scrollDivTop) && watchlistPage) {
      this.handleModalClose();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('scroll', this.onScroll);
    if (this.sourceCancelToken) {
      this.sourceCancelToken.cancel();
    }
  }

  handlePageRedirect(e, item) {
    const { history, isFinpromptPages } = this.props;

    e?.stopPropagation();

    EntitiesSvc.getEntityPermalink({
      entityId: item.entity_id,
      entityType: item.entity_type,
    }).then(({ permalink }) => {
      if (permalink) {
        const correctLink = convertPermalinkToPath(permalink);
        if (isFinpromptPages) {
          setTimeout(() => {
            window.open(`${CF_URL_FOR_FINPROMPT}${correctLink}`, '_blank')?.focus?.();
          }, 100);
        } else {
          history.push(correctLink);
        }
      }
    });
  }

  onHideStory(id) {
    const { expandFilter, onHide } = this.props;

    this.toggleStoryExpandState(expandFilter).then(() => {
      if (onHide) onHide(id);
    });
  }

  onScroll() {
    this.setState({
      showSentimentPopover: false,
      showScorePopover: false,
    });
  }

  onShowPremiumLabelHideModal(e) {
    e.stopPropagation();
    this.setState({
      showHideLabelModal: true,
    });
  }

  onClosePremiumLabelHideModal() {
    this.setState({
      showHideLabelModal: false,
    });
  }

  onHidePremiumLabel(e) {
    const { storyFilters: { isPremiumLabelsEnabled }, actions } = this.props;
    e.stopPropagation();
    StorageSvc.setItem('showPremiumLabels', JSON.stringify({ status: !isPremiumLabelsEnabled }));
    actions.togglePremiumLabels(!isPremiumLabelsEnabled);
    this.onClosePremiumLabelHideModal();
  }

  getFollowersFormat(num) {
    switch (true) {
      case num > 999 && num < 999999:
        return `${Math.floor(num / 1000)}k`;
      case num > 999999:
        return `${Math.floor(num / 1000000)}m`;
      default:
        return num;
    }
  }

  async setTranslations() {
    const {
      story, translationSupportedLanguages, showTranslation, storyFilters,
    } = this.props;
    const { languages } = storyFilters;
    if (!story?.translations?.length && languages !== 'en') {
      const isTranslationSupported = translationSupportedLanguages?.map((el) => el.code).toString().split(',')?.includes(story.lang);
      if (!story?.translations?.length && isTranslationSupported && showTranslation) {
        if (this.sourceCancelToken) {
          this.sourceCancelToken.cancel();
          this.sourceCancelToken = null;
        }
        if (!this.sourceCancelToken) {
          this.sourceCancelToken = axios.CancelToken.source();
        }

        const translatedData = await StoriesSvc.getTranslatedStory(story.uuid, 'en', this.sourceCancelToken.token);
        if (translatedData?.cancelled) return;
        story.translations = translatedData?.data?.article_translations;
        this.setState({
          translation: {
            title: story.translations[0].title,
            description: story.translations[0].description,
          },
        });
      } else if (this.sourceCancelToken) {
        this.sourceCancelToken.cancel();
        this.sourceCancelToken = null;
      }
    }
    if (story.translations !== null && story.translations && story.translations.length) {
      this.setState({
        translation: {
          title: story.translations[0].title,
          description: story.translations[0].description,
        },
      });
    }
  }

  openCurrShownImageInNewTab(e) {
    e.stopPropagation();
    const { story } = this.props;
    const imgURL = story.imageUrls?.[0];
    window.open(imgURL, '_blank')?.focus?.();
  }

  toggleStoryExpandState(value) {
    return new Promise(((resolve) => {
      this.setState(({ expanded }) => ({
        expanded: typeof value === 'boolean' ? value : !expanded,
      }), resolve);
    }));
  }

  async fetchSimilarStories() {
    const {
      story,
      groupSimilarsFilter,
      popup,
      inHP,
      storyFilters,
      permissions,
      useFoldedSimilarStories,
      userNewsfeedView,
      userNewsfeedViewCompact,
      skipSimilarStories,
    } = this.props;
    let storiesLimit;

    if (!popup && !inHP) {
      storiesLimit = userNewsfeedView === 'tiles' && !userNewsfeedViewCompact ? 3 : 5;
    } else {
      storiesLimit = 2;
    }

    if (
      story.similar_stories_count
      && groupSimilarsFilter
      && !skipSimilarStories
    ) {
      if (useFoldedSimilarStories) {
        this.setState({
          similarStories: {
            stories: story.similar_stories?.slice(0, storiesLimit) ?? [],
            loading: false,
            allCount: story.similar_stories?.length ?? 0,
          },
        }, () => {
          this.resizeGridItem();
        });
        return;
      }

      this.setState({
        similarStories: {
          stories: [],
          loading: true,
          allCount: 0,
        },
      });
      const {
        all_languages: allLanguages, languages, ...restParams
      } = storyFilters;
      const { stories } = await StoriesSvc.getSimilarStoriesDirectly(story, {
        ...restParams,
        limit: 20,
        maxSearchPeriod: permissions.search_period,
      });

      if (this.mounted) {
        this.setState({
          similarStories: {
            stories: stories.slice(0, storiesLimit),
            loading: false,
            allCount: stories.length,
          },
        }, () => {
          this.resizeGridItem();
        });
      }
    }
  }

  toggleExpand(e) {
    const isCompactViewButton = this.expandRowViewButtonRef?.current?.contains(e?.target);
    const isRowsViewButton = this.expandCompactViewButtonRef?.current?.contains(e?.target);

    if (this.sentimentContentRef?.current?.contains(e?.target)
    && (!isCompactViewButton && !isRowsViewButton)) {
      return;
    }
    e?.stopPropagation();
    this.handleModalClose(e);
    const {
      client,
      inHP,
      story,
      onFreezeStories,
      token,
    } = this.props;

    const clientName = (client && client.name) || '';
    if ([RBI.name].includes(clientName) || inHP) return;

    this.toggleStoryExpandState().then(() => {
      const { expanded } = this.state;

      onFreezeStories(expanded);
      if (expanded && token) API.post(`stories/${story.id}/view_description`);
    });
  }

  toggleTranslation(e) {
    const { isTranslationShown, translation } = this.state;
    e.stopPropagation();
    const newState = !isTranslationShown;

    if (translation.title) {
      this.setState({
        isTranslationShown: newState,
      });
    } else {
      this.setState({
        temporaryTranslationMessage: true,
      }, () => {
        setTimeout(() => {
          if (this.mounted) this.setState({ temporaryTranslationMessage: false });
        }, 15000);
      });
    }
  }

  openURL(e, url, isSamePage) {
    const {
      inHP, onFreezeStories, history, isFinpromptPages,
    } = this.props;

    if (e) e.stopPropagation();
    if (!inHP) onFreezeStories(true);

    const correctPath = isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}${url}` : url;

    if (isFinpromptPages && isSamePage) {
      window.open(correctPath, '_blank')?.focus?.();
    } else if (isSamePage) {
      history.push(url);
    } else {
      window.open(url, '_blank')?.focus?.();
    }
  }

  tweetAction(type) {
    const {
      inHP,
      onFreezeStories,
      story,
      piwikEnabled,
      token,
    } = this.props;
    const y = window.outerHeight / 2 + window.screenY - (570 / 2);
    const x = window.outerWidth / 2 + window.screenX - (520 / 2);

    if (!token) {
      return this.openSignModal();
    }
    if (!inHP) {
      onFreezeStories(true);
    }

    if (type === 'in_reply_to') {
      if (piwikEnabled) _paq.push(['trackEvent', 'Story interaction', 'Tweet reply']);

      window.open(
        `https://twitter.com/intent/tweet?${type}=${story.additional_data.tweet_id}&original_referer=${window.location.href}`,
        '_blank',
        `location=yes,height=570,width=520,scrollbars=yes,status=yes,top=${y},left=${x}`,
      )?.focus?.();
    } else {
      if (piwikEnabled && type === 'retweet') _paq.push(['trackEvent', 'Story interaction', 'Retweet']);
      if (piwikEnabled && type === 'favorite') _paq.push(['trackEvent', 'Story interaction', 'Tweet like']);

      window.open(
        `https://twitter.com/intent/${type}?tweet_id=${story.additional_data.tweet_id}&original_referer=${window.location.href}`,
        '_blank',
        `location=yes,height=570,width=520,scrollbars=yes,status=yes,top=${y},left=${x}`,
      )?.focus?.();
    }
  }

  loadStoryImage() {
    const { story, client, type } = this.props;

    let images = [story.author_image_url, story.domain_cached_large_logo_url];

    if (client && RBI.name === client.name) {
      return this.setState({
        storyImage: RBILogo,
      });
    }

    if (type !== 'Tweet') {
      images = images.reverse();
    }

    this.checkImageLoading(images[0], () => {
      if (this.mounted) this.setState({ storyImage: images[0] });
    }, () => {
      this.checkImageLoading(images[1], () => {
        if (this.mounted) this.setState({ storyImage: images[1] });
      }, () => {
        if (this.mounted) this.setState({ storyImage: defaultStoryImg });
      });
    });
  }

  checkImageLoading(imageUrl, load, notLoad) {
    const img = new Image();

    img.onload = load;
    img.onerror = notLoad;

    img.src = imageUrl;
  }

  togglePopOver(e, item) {
    const { inHP, onFreezeStories, isRecommendedStories } = this.props;
    const cardDivRef = this.cardDiv.current;
    const rect = cardDivRef.getBoundingClientRect();
    const parentDiv = isRecommendedStories
      ? cardDivRef.parentNode.parentNode.parentNode
      : cardDivRef.parentNode;
    const rectParent = parentDiv.getBoundingClientRect();
    let isToolTipTop = true;
    if (item === 'showScorePopover') {
      isToolTipTop = rect.top - rectParent.top > 98;
    } else {
      isToolTipTop = rect.top - rectParent.top > 36;
    }

    this.setState((state) => ({
      [item]: !state[item],
      isToolTipTop,
    }), () => {
      const { [item]: value } = this.state;
      if (!inHP) onFreezeStories(value);
    });
    debounce(() => {
      const { [item]: value } = this.state;
      if (!value || !(item === 'showScorePopover' || item === 'showSentimentPopover')) return;

      this.setState({
        [item]: false,
      }, () => {
        if (!inHP) onFreezeStories(false);
      });
    }, 5000)();
  }

  popupEnter(e, item) {
    e.persist();
    this.popUpTimer = setTimeout(() => {
      this.togglePopOver(e, item);
    }, 2000);
  }

  popupLeave() {
    clearTimeout(this.popUpTimer);
    this.popUpTimer = 0;
  }

  cardContentInteraction(e) {
    const { inHP, trendingBarCard, popup } = this.props;

    if (!inHP && !trendingBarCard && !popup) {
      // when card not expanded and user click on story body space
      this.toggleExpand(e);
    }
  }

  openSignModal() {
    const { inHP, onFreezeStories, actions } = this.props;

    actions.setAuthModalOpened(true);
    if (!inHP) onFreezeStories(true);
  }

  openTwitterImagesModal(e) {
    e.stopPropagation();
    const {
      inHP,
      onFreezeStories,
      piwikEnabled,
      actions,
    } = this.props;

    if (piwikEnabled) _paq.push(['trackEvent', 'Story interaction', 'View picture']);

    if (!inHP) onFreezeStories(true);
    if (this.twitterImgSliderModal) {
      this.twitterImgSliderModal.handleShow();
    }
    actions.setStoryImageOpened(true);
  }

  openStory(e, forceSkipSource = false, storyObj = null, callAPI = true) {
    let story = storyObj;
    const { piwikEnabled, story: mainStory, token } = this.props;
    if (!storyObj) {
      story = mainStory;
    }
    const url = story.url || story.expanded_url || (story.type === 'Tweet' ? `https://twitter.com/i/web/status/${story.additional_data.tweet_id}` : '');

    if (token) API.post(`stories/${story.id}/track_clicked_link`);

    if (piwikEnabled) _paq.push(['trackEvent', 'Story interaction', 'View story']);

    if (callAPI) {
      const isSingleStoryUrl = (forceSkipSource || story.lowest_premium_plan === GOLD_PLAN) && story.type !== 'Tweet';
      this.openURL(e, isSingleStoryUrl ? `/news/stories/${story.uuid_title}` : url, isSingleStoryUrl);
    }

    this.setState(({ visited }) => ({
      visited: storyObj ? visited : true,
    }));
  }

  closeSimilars() {
    const { actions } = this.props;
    actions.toggleSimilarStoriesModal(true);
    this.setState({
      storiesHideModalVisible: true,
    });
  }

  closeSimilarsModal(e) {
    const { actions, popup } = this.props;
    setTimeout(() => {
      actions.toggleSimilarStoriesModal(false);
    });
    this.setState({
      storiesHideModalVisible: false,
    });
    if (!e && !popup) this.toggleStoryExpandState();
  }

  hideSimilars(e) {
    const { actions, token } = this.props;

    actions.toggleShowSimilars(false, token);

    this.closeSimilarsModal(e);
  }

  storyInfoAction(e) {
    e.preventDefault();
    const { piwikEnabled } = this.props;

    if (piwikEnabled) _paq.push(['trackEvent', 'Story interaction', 'Story info']);

    this.openStory(e, false);
  }

  imageLoadEvent() {
    this.setState({ twitterImgLoaded: true }, () => {
      this.resizeGridItem();
    });
  }

  imageErrorEvent() {
    const { isFoxy } = this.state;
    if (isFoxy) {
      return this.setState({
        isFoxy: false,
      });
    }
    this.setState({
      imageLoadingError: true,
    });
  }

  domainImageErrorEvent() {
    const { type } = this.props;
    const { domainImageTypeFirst } = this.state;

    this.setState({
      domainLoadingError: !(type === 'Tweet' && domainImageTypeFirst),
      domainImageTypeFirst: false,
    });
  }

  setTitleRef(refValue) {
    if (!refValue) return;
    this.titleRef.current = refValue;

    this.setState({
      titleTooltip: refValue.offsetHeight < refValue.scrollHeight,
    });
  }

  setTitleHidden() {
    const { titleTooltip } = this.state;
    if (!this.titleRef.current || titleTooltip) return;

    this.setState({
      titleTooltip: this.titleRef.current.offsetHeight < this.titleRef.current.scrollHeight,
    });
  }

  resizeGridItem() {
    const {
      popup,
      userNewsfeedView,
      forceRowsView,
      userNewsfeedViewCompact,
    } = this.props;
    if (userNewsfeedView === 'tiles' && !popup && !forceRowsView && !userNewsfeedViewCompact) {
      const { masonryObj, homepageStories } = this.props;
      if (masonryObj && homepageStories) {
        masonryObj.current.layout();
      } else if (masonryObj) {
        masonryObj.layout();
      }
    }
  }

  renderType(item) {
    if (item?.asset_class) {
      return item?.asset_class;
    }
    if (item?.entity_type.toLowerCase() === 'assetclass') {
      return 'Topic Class';
    }
    if (item?.entity_type.toLowerCase() === 'startupcategory') {
      return 'Sector Category';
    }
    return item?.entity_type;
  }

  async toggleRemovedBookmark(e, story) {
    if (e) e.stopPropagation();
    const { removedBookmark } = this.state;
    this.setState({
      removedBookmark: !removedBookmark,
    });

    if (removedBookmark) {
      return StoriesApi.addStoryToBookmark(story.id);
    }
  }

  // eslint-disable-next-line react/sort-comp
  handleTopicSelect(e, topic) {
    const {
      width, promoHeight,
    } = this.props;
    if (width <= 1024) {
      this.handlePageRedirect(e, topic);
      return;
    }

    const offsetY = e.currentTarget?.getBoundingClientRect()?.top;
    const pageY = window.pageYOffset + offsetY;

    const pageX = e.currentTarget.getBoundingClientRect().left;
    const currentWidth = e.currentTarget.clientWidth / 2;

    let x;
    let y = pageY + 38 - promoHeight;
    let arrowLeft = 'calc(50% - 9px)';
    let height;
    let overflow;
    if (width >= 2800) {
      y = pageY + 76 - promoHeight;
      if (pageX + 920 + currentWidth <= window.innerWidth) {
        if (pageX - 920 + currentWidth > 40) {
          x = pageX - 900 + currentWidth;
        } else {
          x = 40;
          arrowLeft = `${pageX + currentWidth - 58}px`;
        }
      } else {
        x = window.innerWidth - 1840;
        arrowLeft = `${pageX - x - 18 + currentWidth}px`;
      }
    } else if (pageX + 460 + currentWidth <= window.innerWidth) {
      if (pageX - 460 + currentWidth > 20) {
        x = pageX - 450 + currentWidth;
      } else {
        x = 20;
        arrowLeft = `${pageX + currentWidth - 29}px`;
      }
    } else {
      x = window.innerWidth - 920;
      arrowLeft = `${pageX - x - 9 + currentWidth}px`;
    }

    const elHeight = document.querySelector('.clearfix').getBoundingClientRect()?.height;
    if (topic
                && (elHeight - y) < 519) {
      height = (elHeight - y - 20);
      overflow = 'auto';
    }

    const query = getTrendingBarAssetQuery({
      id: topic.entity_id,
      name: topic.entity_name,
    }, topic.entity_type);

    this.setState({
      query,
      selectedTopic: {
        ...topic,
        x,
        y,
        arrowLeft,
        height,
        overflow,
      },
    });
  }

  handleModalClose() {
    this.setState({
      selectedTopic: null,
      query: '',
    });
  }

  async handleTopicRedirect(selectedTopic) {
    const { history, isSectors } = this.props;

    let topic;
    let topicURL = '';
    if (isSectors) {
      topic = await SectorsSvc.getCategory(selectedTopic.entity_id);
      topicURL = `/news/directory/${SECTORS_PATH_ITEM}/${topic.subindustry.industry.sector.slug}/${topic.subindustry.industry.slug}/${topic.subindustry.slug}/${topic.slug}`;
    } else {
      topic = await TopicsSvc.getTopicById(selectedTopic.entity_id);
      const assetClassSlug = topic?.asset_class?.slug;
      const topicSlug = topic?.slug;

      topicURL = `/news/directory/${assetClassSlug}/${topicSlug}`;
    }

    history.push(topicURL);
  }

  mouseEnterHandler() {
    this.setState({
      currentIcon: faLanguageRegular,
    });
  }

  mouseLeaveHandler() {
    this.setState({
      currentIcon: faLanguageLight,
    });
  }

  render() {
    const {
      expanded,
      storyImage,
      twitterImgLoaded,
      showPaywallPopover,
      showRegisterPopover,
      showScorePopover,
      isTranslationShown,
      translation,
      temporaryTranslationMessage,
      similarStories,
      storiesHideModalVisible,
      isToolTipTop,
      visited,
      imageLoadingError,
      domainLoadingError,
      domainImageTypeFirst,
      sentimentValue,
      showSentimentPopover,
      isFoxy,
      showHideLabelModal,
      titleTooltip,
      removedBookmark,
      selectedTopic,
      query,
      currentIcon,
    } = this.state;
    const {
      story,
      type,
      trendingBarCard,
      popup,
      inHP,
      client,
      showTranslation,
      user,
      curationMode,
      showSimilarsFilter,
      groupSimilarsFilter,
      storyFilters,
      actions,
      userNewsfeedViewImages,
      userNewsfeedViewDescription,
      userNewsfeedView,
      userNewsfeedViewCompact,
      forceRowsView,
      width,
      isRecommendedStories,
      onLikeChanged,
      onBookmarkClick,
      onFreezeStories,
      hasBigScreenDesign,
      skipSimilarStories,
      homepageStories,
      trimTitle,
      permissionsInitialized,
      accessLevels,
      index,
      piwikEnabled,
      isWatchlist,
      slugName,
      disableMainDomainImageLoad,
      isModal,
      activeTopics,
      isSEOPage,
      trendingPageStories,
      slugType,
      bookmarksPage,
      promoHeight,
      translationSupportedLanguages,
    } = this.props;
    const admin = Boolean(user?.is_curator && curationMode);
    const isMobile = width <= 480;
    const isDesktop = width > 768;
    const clientName = client?.name || '';
    const imageHeight = userNewsfeedViewImages && this.imageRef.current ? (
      `${this.imageRef.current.offsetHeight - (userNewsfeedViewDescription || !this.descRef.current ? 0 : this.descRef.current.offsetHeight)}px`
    ) : 'auto';

    const isRowCardView = forceRowsView || userNewsfeedView === 'rows' || popup || userNewsfeedViewCompact;
    const isTileCardView = userNewsfeedView === 'tiles' && !popup && !forceRowsView && !userNewsfeedViewCompact;
    const isCompactView = userNewsfeedViewCompact && !popup && !forceRowsView;
    const isCompactExpandView = isCompactView && expanded;
    const cardClass = cx({
      Card: forceRowsView || userNewsfeedView === 'rows' || popup || userNewsfeedViewCompact,
      TileCard: isTileCardView,
      Compact: isCompactView,
      'trending-bar-card': trendingBarCard,
      popup,
      expanded: expanded && !isTileCardView,
      recommended: isRecommendedStories,
      big_screen: hasBigScreenDesign,
      homepage: homepageStories,
    });

    const firstImgUrl = story.imageUrls?.[0];
    const imageURL = returnCDNFoxyImageUrl({
      isFoxy, imageURL: firstImgUrl,
    });

    const items = sortAndOrderInfoLabelItems(story.analytics, slugName);
    const filteredTopics = activeTopics?.map((item) => item.name.toLowerCase());
    let stockLabelItems;
    if (isWatchlist) {
      stockLabelItems = (items?.filter((item) => filteredTopics
        ?.includes(item?.entity_name?.toLowerCase())));
    } else if (expanded && (!isWatchlist && slugType !== undefined)) {
      stockLabelItems = ((items?.filter((item) => (item
        ?.entity_name?.toLowerCase() === slugName?.toLowerCase()
        && item?.entity_type.toLowerCase() === slugType?.toLowerCase()))));
    } else if (expanded && !isWatchlist) {
      stockLabelItems = ((items?.filter((item) => item
        ?.entity_name?.toLowerCase() === slugName?.toLowerCase())));
    } else {
      stockLabelItems = ((items?.filter((item) => item
        ?.entity_name?.toLowerCase()
        === slugName?.toLowerCase()))).slice(0, 5);
    }

    const stocksLabels = isMobile ? stockLabelItems?.map((item) => (
      <SentimentTag
        key={item.entity_id}
        item={item}
        selectedTopic={selectedTopic}
        handleTopicSelect={this.handleTopicSelect}
        width={width}
        promoHeight={promoHeight}
        isInfoItemLabel
        hideSentimentScores
        isDisabled={item.entity_name.toLowerCase() === slugName?.toLowerCase()}
      />
    )).slice(0, 5)
      : stockLabelItems?.map((item) => (
        <SentimentTag
          key={item.entity_id}
          item={item}
          selectedTopic={selectedTopic}
          handleTopicSelect={this.handleTopicSelect}
          width={width}
          promoHeight={promoHeight}
          isInfoItemLabel
          hideSentimentScores
          isDisabled={item.entity_name.toLowerCase() === slugName?.toLowerCase()}
        />
      )).slice(0, 10);
    let otherLabelsItems;
    if (isWatchlist && !expanded && (!isMobile && !isTablet)) {
      otherLabelsItems = (items?.filter((item) => !filteredTopics
        ?.includes(item?.entity_name?.toLowerCase()))).slice(0, 5);
    } else if (isWatchlist && (isMobile || isTablet)) {
      otherLabelsItems = (items?.filter((item) => !filteredTopics
        ?.includes(item?.entity_name?.toLowerCase())));
    } else if (expanded && isWatchlist) {
      otherLabelsItems = (items?.filter((item) => !filteredTopics
        ?.includes(item?.entity_name?.toLowerCase())));
    } else if (expanded && (!isWatchlist && slugType !== undefined)) {
      otherLabelsItems = ((items?.filter((item) => (item
        ?.entity_name?.toLowerCase() !== slugName?.toLowerCase()
        && item?.entity_type.toLowerCase() !== slugType?.toLowerCase()))));
    } else if ((expanded && !isWatchlist)
      || (homepageStories && isTileCardView)
      || (homepageStories && expanded)) {
      otherLabelsItems = (items?.filter((item) => item
        ?.entity_name?.toLowerCase() !== slugName?.toLowerCase()));
    } else if (((homepageStories && !expanded)
      || (width <= 1365 && !isTileCardView))
      && (!isMobile && !isTablet)) {
      otherLabelsItems = (items?.filter((item) => !filteredTopics
        ?.includes(item?.entity_name?.toLowerCase()))).slice(0, 1);
    } else if (((trendingPageStories && !expanded && !isTileCardView))
      && (!isMobile && !isTablet)) {
      otherLabelsItems = (items?.filter((item) => !filteredTopics
        ?.includes(item?.entity_name?.toLowerCase()))).slice(0, 2);
    } else if (isMobile || isTablet) {
      otherLabelsItems = (items?.filter((item) => item
        ?.entity_name?.toLowerCase() !== slugName?.toLowerCase()));
    } else {
      otherLabelsItems = (items?.filter((item) => item
        ?.entity_name?.toLowerCase() !== slugName?.toLowerCase())).slice(0, 5);
    }

    const otherLabels = isMobile ? otherLabelsItems?.map((item) => (
      <SentimentTag
        key={item.entity_id}
        item={item}
        selectedTopic={selectedTopic}
        handleTopicSelect={this.handleTopicSelect}
        width={width}
        promoHeight={promoHeight}
        isInfoItemLabel
        hideSentimentScores
        isDisabled={item.entity_name.toLowerCase() === slugName?.toLowerCase()}
      />
    )).slice(0, (5 - stocksLabels.length))
      : otherLabelsItems?.map((item) => (
        <SentimentTag
          key={item.entity_id}
          item={item}
          selectedTopic={selectedTopic}
          handleTopicSelect={this.handleTopicSelect}
          width={width}
          promoHeight={promoHeight}
          isInfoItemLabel
          hideSentimentScores
          isDisabled={item.entity_name.toLowerCase() === slugName?.toLowerCase()}
        />
      )).slice(0, (10 - stocksLabels.length));

    const tweetImage = domainImageTypeFirst
      ? (story.author_image_url || '').replace('normal', '400x400') : story.author_image_url;
    const domainImageURL = type === 'Tweet' ? tweetImage : storyImage;
    const allowMainImage = userNewsfeedViewImages && firstImgUrl && !imageLoadingError;
    const allowDomainImage = !allowMainImage && userNewsfeedViewImages && !userNewsfeedViewCompact
      && !disableMainDomainImageLoad && domainImageURL && !domainLoadingError;
    const withoutImages = !allowMainImage && !allowDomainImage;

    const isGoldPlan = story.lowest_premium_plan === GOLD_PLAN && type !== 'Tweet';
    const isSilverPlan = story.lowest_premium_plan === SILVER_PLAN && type !== 'Tweet';

    const isTranslationSupported = translationSupportedLanguages?.map((el) => el.code)?.toString()?.split(',')?.includes(story.lang);
    return (
      <>
        {!removedBookmark ? (
          <>
            <div
              ref={this.cardDiv}
              className={cardClass}
            >
              {isRowCardView && (
                <div
                  className={cx('Card__content', {
                    bookmarked: bookmarksPage,
                    'without-images': !userNewsfeedViewImages || withoutImages,
                  })}
                  ref={this.imageRef}
                >
                  {bookmarksPage
                    && (
                    <BookmarkStoryIcon
                      width={width}
                      toggleRemovedBookmark={(e) => this.toggleRemovedBookmark(e)}
                      bookmark={story}
                    />
                    )}
                  {isGoldPlan
                    && storyFilters.isPremiumLabelsEnabled
                    && (
                      <div className={cx('tooltip__container')}>
                        <div className={cx('goldIcon')}>
                          <div className={cx('goldIcon__text')}>
                            G
                          </div>
                          <div className={cx('label__tooltip')}>
                            <div className={cx('label__tooltip--container')}>
                              <div className={cx('label__tooltip--content')}>
                                <div className={cx('premium-gold-icon-badge')}>
                                  GOLD
                                </div>
                                <div className={cx('tooltip-text')}>
                                  Exclusive content for Gold users
                                </div>
                              </div>
                              <div className={cx('tooltip_button')} onClick={this.onShowPremiumLabelHideModal}>
                                <FontAwesomeIcon icon={faEyeSlash} />
                                <div className={cx('tooltip_button-text')}>Hide Labels</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  {isSilverPlan
                    && storyFilters.isPremiumLabelsEnabled
                    && (
                      <div className={cx('tooltip__container')}>
                        <div className={cx('silverIcon')}>
                          <div className={cx('silverIcon__text')}>
                            S
                          </div>
                          <div className={cx('label__tooltip')}>
                            <div className={cx('label__tooltip--container')}>
                              <div className={cx('label__tooltip--content')}>
                                <div className={cx('premium-silver-icon-badge')}>
                                  SILVER
                                </div>
                                <div className={cx('tooltip-text')}>
                                  Additional content for Premium users
                                </div>
                              </div>
                              <div className={cx('tooltip_button')} onClick={this.onShowPremiumLabelHideModal}>
                                <FontAwesomeIcon icon={faEyeSlash} />
                                <div>Hide Labels</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {isCompactView && (
                    <>
                      {type === 'Tweet' ? (
                        <img src={story.author_image_url} className={cx('twitter-image')} alt="" />
                      ) : (
                        <img
                          src={storyImage}
                          className={cx('source-image')}
                          loading="lazy"
                          alt=""
                        />
                      )}
                    </>
                  )}
                  <div className={cx('Card__content--details')} ref={this.sentimentContentRef}>
                    <div className={cx('details-header')}>
                      <span className={cx('details-header__info')}>
                        <div className={cx('details-header__info--container')}>
                          {userNewsfeedViewImages && !withoutImages && (
                          <div
                            className={cx('Card__content--img', { bookmarked: bookmarksPage })}
                          >
                            <div
                              className="details-header__statistics--twitter-img"
                              onClick={this.openTwitterImagesModal}
                            >
                              {allowMainImage && !allowDomainImage ? (
                                <img
                                  src={imageURL}
                                  onLoad={() => this.setState({ twitterImgLoaded: true })}
                                  loading="lazy"
                                  onError={this.imageErrorEvent}
                                  alt="Story"
                                  className={cx({ 'details-header__statistics--twitter-img-bookmark': bookmarksPage })}
                                />
                              ) : (
                                <img
                                  src={domainImageURL}
                                  onLoad={() => this.setState({ twitterImgLoaded: true })}
                                  loading="lazy"
                                  onError={this.domainImageErrorEvent}
                                  alt="Story"
                                  className={cx({ 'details-header__statistics--twitter-img-bookmark': bookmarksPage })}
                                />
                              )}
                            </div>
                            {(story.imageUrls?.length > 1) && (
                            <span className={cx('details-header__info--images')}>
                              +
                              {story.imageUrls.length}
                            </span>
                            )}
                          </div>
                          )}
                          {trimTitle ? (
                            <Tooltip
                              className={cx('details-header__info--title-tooltip', { hidden: !titleTooltip })}
                              icon={(
                                <span
                                  className={cx('details-header__info--title', { expanded, visited, trim_title: true })}
                                  onClick={this.storyInfoAction}
                                  ref={this.setTitleRef}
                                  onMouseEnter={this.setTitleHidden}
                                >
                                  {isTranslationShown
                                  && translation.title ? translation.title : story.title}
                                </span>
                            )}
                            >
                              {story.title}
                            </Tooltip>
                          ) : (
                            <span
                              className={cx('details-header__info--title', { expanded, visited })}
                              onClick={this.storyInfoAction}
                            >
                              {isTranslationShown && translation.title
                                ? translation.title : story.title}
                            </span>
                          )}
                        </div>
                        <div
                          ref={this.descRef}
                          className={cx('details-description--container')}
                        >
                          {type === 'Story' && !userNewsfeedViewCompact && (
                            <div
                              className={expanded || (userNewsfeedViewDescription && !popup && !inHP) ? 'details-description show' : 'details-description'}
                            >
                              {isTranslationShown && translation.description
                                ? translation.description : story.description}
                            </div>
                          )}
                        </div>
                        <span className={cx('details-header__info--details')}>
                          <span className={cx('details-header__info--aboutContent')}>
                            <span
                              className={classNames('details-header__info--about', { tweet: type === 'Tweet', expanded }, { 'show-popover': showScorePopover })}
                            >
                              {showTranslation && isTranslationSupported && story.lang !== 'en' && !inHP && !isCompactExpandView && isCompactView && width > 768 && (
                              <span
                                className={cx('details-header__info--translate')}
                                onMouseEnter={this.mouseEnterHandler}
                                onMouseLeave={this.mouseLeaveHandler}
                              >
                                <Tooltip
                                  icon={(<FontAwesomeIcon icon={currentIcon} />)}
                                  hasBigScreenDesign
                                  isBigScreen
                                >
                                  <span className={cx('title')}>
                                    {(() => {
                                      if (translation && translation.title) {
                                        if (isTranslationShown) {
                                          return (
                                            <>
                                              <div className={cx('translated--from--title')}>
                                                {`Translated from ${translationSupportedLanguages
                                                  ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                                              </div>
                                              <div
                                                className={cx('translate--title')}
                                                onClick={this.toggleTranslation}
                                              >
                                                Show Original
                                              </div>
                                            </>
                                          );
                                        }
                                        return (
                                          <>
                                            <div className={cx('translated--from--title')}>
                                              {`Original in ${translationSupportedLanguages
                                                ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                                            </div>
                                            <div
                                              className={cx('translate--title')}
                                              onClick={this.toggleTranslation}
                                            >
                                              Translate to English
                                            </div>
                                          </>
                                        );
                                      }
                                      return 'Translating to English...';
                                    })()}
                                  </span>
                                </Tooltip>
                              </span>
                              )}
                              {type === 'Tweet' ? (
                                <>
                                  <img src={story.author_image_url} className={cx('twitter-image')} alt="" />
                                  <FontAwesomeIcon className={cx('twitter-icon')} icon={faTwitter} />
                                  <span
                                    className={cx('twitter-handle')}
                                    onClick={(e) => this.openURL(e, `https://www.twitter.com/intent/user?screen_name=${story.author_screen_name}`)}
                                  >
                                    @
                                    {story.author_screen_name}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={storyImage}
                                    className={cx('source-image')}
                                    loading="lazy"
                                    alt=""
                                  />
                                  <span>{story.domain_name}</span>

                                </>
                              )}
                              <span>
                                {calculateTimeAgo(story.publishTime, width <= 1500)}
                              </span>
                              {showTranslation && isTranslationSupported && story.lang !== 'en' && !inHP && !isCompactExpandView && isCompactView && width < 767 && (
                              <span
                                className={cx('details-header__info--translate')}
                                onMouseEnter={this.mouseEnterHandler}
                                onMouseLeave={this.mouseLeaveHandler}
                              >
                                <Tooltip
                                  icon={(<FontAwesomeIcon icon={currentIcon} />)}
                                  hasBigScreenDesign
                                  isBigScreen
                                  dynamicPosition
                                >
                                  <span className={cx('title')}>
                                    {(() => {
                                      if (translation && translation.title) {
                                        if (isTranslationShown) {
                                          return (
                                            <>
                                              <div className={cx('translated--from--title')}>
                                                {`Translated from ${translationSupportedLanguages
                                                  ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                                              </div>
                                              <div
                                                className={cx('translate--title')}
                                                onClick={this.toggleTranslation}
                                              >
                                                Show Original
                                              </div>
                                            </>
                                          );
                                        }
                                        return (
                                          <>
                                            <div className={cx('translated--from--title')}>
                                              {`Original in ${translationSupportedLanguages
                                                ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                                            </div>
                                            <div
                                              className={cx('translate--title')}
                                              onClick={this.toggleTranslation}
                                            >
                                              Translate to English
                                            </div>
                                          </>
                                        );
                                      }
                                      return 'Translating to English...';
                                    })()}
                                  </span>
                                </Tooltip>
                              </span>
                              )}
                            </span>
                            {(!isCompactView || (isCompactView && width >= 768)) && (
                            <div className={cx('details-header__info--more-details')}>
                              <>
                                <div
                                  className={cx(
                                    'details-header__statistics--percentage',
                                    { compact: isCompactView },
                                    { popup },
                                    { expanded },
                                    { 'show-popover': showScorePopover },
                                  )}
                                  onClick={(e) => this.togglePopOver(e, 'showScorePopover')}
                                  onMouseEnter={(e) => this.popupEnter(e, 'showScorePopover')}
                                  onMouseLeave={this.popupLeave}
                                >
                                  <span className={cx('score-value')}>
                                    {Math.round(story.score)}
                                    %
                                  </span>
                                  <span className={cx('percentage-popover', `percentage-popover-${isToolTipTop ? 'top' : 'bottom'}`)}>
                                    <span className={cx('percentage-popover-container')}>
                                      CityFALCON Score showing relevance&nbsp;
                                      of story to you and your chosen topics.
                                      <a href="https://www.cityfalcon.ai/features/cf-score" rel="noopener noreferrer">
                                        <span>How does it work?</span>
                                      </a>
                                    </span>
                                  </span>
                                </div>
                                {showScorePopover && (
                                <span className={cx('popover_backdrop')} onClick={(e) => this.togglePopOver(e, 'showScorePopover')} />
                                )}
                              </>
                              {showTranslation && isTranslationSupported && story.lang !== 'en' && !inHP && (isCompactExpandView || (isRowCardView && !isCompactView)) && (
                                <span
                                  className={cx('details-header__info--translate')}
                                  onMouseEnter={this.mouseEnterHandler}
                                  onMouseLeave={this.mouseLeaveHandler}
                                >

                                  <Tooltip
                                    className={cx('translate--tooltip')}
                                    icon={(<FontAwesomeIcon icon={currentIcon} />)}
                                    hasBigScreenDesign
                                    isBigScreen
                                  >
                                    <span className={cx('title')}>
                                      {(() => {
                                        if (translation && translation.title) {
                                          if (isTranslationShown) {
                                            return (
                                              <>
                                                <div className={cx('translated--from--title')}>
                                                  {`Translated from ${translationSupportedLanguages
                                                    ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                                                </div>
                                                <div
                                                  className={cx('translate--title')}
                                                  onClick={this.toggleTranslation}
                                                >
                                                  Show Original
                                                </div>
                                              </>
                                            );
                                          }
                                          return (
                                            <>
                                              <div className={cx('translated--from--title')}>
                                                {`Original in ${translationSupportedLanguages
                                                  ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                                              </div>
                                              <div
                                                className={cx('translate--title')}
                                                onClick={this.toggleTranslation}
                                              >
                                                Translate to English
                                              </div>
                                            </>
                                          );
                                        }
                                        return 'Translating to English...';
                                      })()}
                                    </span>
                                  </Tooltip>
                                  <span className={cx('title')}>
                                    {(() => {
                                      if (translation && translation.title) {
                                        if (isTranslationShown) {
                                          return (
                                            <>
                                              <span className={cx('translated--from--title')}>
                                                {`Translated from ${translationSupportedLanguages
                                                  ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                                              </span>
                                              <span
                                                className={cx('translate--title')}
                                                onClick={this.toggleTranslation}
                                              >
                                                Show Original
                                              </span>
                                            </>
                                          );
                                        }
                                        return (
                                          <>
                                            <span className={cx('translated--from--title')}>
                                              {`Original in ${translationSupportedLanguages
                                                ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                                            </span>
                                            <span
                                              className={cx('translate--title')}
                                              onClick={this.toggleTranslation}
                                            >
                                              Translate to English
                                            </span>
                                          </>
                                        );
                                      }
                                      return 'Translating to English...';
                                    })()}
                                  </span>
                                </span>
                              )}
                              {width < 768 && (!isCompactView || (isCompactView && expanded))
                            && !isRowCardView && (
                              <span className={cx('details-header__statistics')}>
                                {sentimentValue && (
                                <>
                                  <span
                                    className={cx('statistics_sentiment', { popup }, sentimentValue.type, { 'show-popover': showSentimentPopover })}
                                    onClick={(e) => this.togglePopOver(e, 'showSentimentPopover')}
                                    onMouseEnter={(e) => this.popupEnter(e, 'showSentimentPopover')}
                                    onMouseLeave={this.popupLeave}
                                  >
                                    <FontAwesomeIcon icon={sentimentValue.icon} />
                                    <span className={cx('sentiment-popover', `sentiment-popover-${isToolTipTop ? 'top' : 'bottom'}`)}>
                                      <span className={cx('sentiment-popover-container')}>
                                        Sentiment:
                                        {' '}
                                        {story.sentiment}
                                        %.
                                        <br />
                                        Ranges from -100% to +100%.
                                        {' '}
                                        <a href="https://www.cityfalcon.ai/features/sentiment" target="_blank" rel="noopener noreferrer">
                                          <span>Learn more</span>
                                        </a>
                                      </span>
                                    </span>
                                  </span>
                                  {showSentimentPopover && (
                                  <span className={cx('popover_backdrop')} onClick={(e) => this.togglePopOver(e, 'showSentimentPopover')} />
                                  )}
                                </>
                                )}
                              </span>
                              )}
                            </div>
                            )}
                            {width < 768 && !isCompactView && (
                              <span className={cx('details-header__statistics')}>
                                {sentimentValue && (
                                <>
                                  <span
                                    className={cx('statistics_sentiment', { popup }, sentimentValue.type, { 'show-popover': showSentimentPopover })}
                                    onClick={(e) => this.togglePopOver(e, 'showSentimentPopover')}
                                    onMouseEnter={(e) => this.popupEnter(e, 'showSentimentPopover')}
                                    onMouseLeave={this.popupLeave}
                                  >
                                    <FontAwesomeIcon icon={sentimentValue.icon} />
                                    <span className={cx('sentiment-popover', `sentiment-popover-${isToolTipTop ? 'top' : 'bottom'}`)}>
                                      <span className={cx('sentiment-popover-container')}>
                                        Sentiment:
                                        {' '}
                                        {story.sentiment}
                                        %.
                                        <br />
                                        Ranges from -100% to +100%.
                                        {' '}
                                        <a href="https://www.cityfalcon.ai/features/sentiment" target="_blank" rel="noopener noreferrer">
                                          <span>Learn more</span>
                                        </a>
                                      </span>
                                    </span>
                                  </span>
                                  {showSentimentPopover && (
                                  <span className={cx('popover_backdrop')} onClick={(e) => this.togglePopOver(e, 'showSentimentPopover')} />
                                  )}
                                </>
                                )}
                              </span>
                            )}
                          </span>
                          {width < 768 && isCompactView && (
                            <div className={cx('details-header__info--more-details')}>
                              {(!isCompactView || (isCompactView && expanded)) && (
                              <>
                                <div
                                  className={cx(
                                    'details-header__statistics--percentage',
                                    { compact: isCompactView },
                                    { popup },
                                    { expanded },
                                    { 'show-popover': showScorePopover },
                                  )}
                                  onClick={(e) => this.togglePopOver(e, 'showScorePopover')}
                                  onMouseEnter={(e) => this.popupEnter(e, 'showScorePopover')}
                                  onMouseLeave={this.popupLeave}
                                >
                                  <span className={cx('score-value')}>
                                    {Math.round(story.score)}
                                    %
                                  </span>
                                  <span className={cx('percentage-popover', `percentage-popover-${isToolTipTop ? 'top' : 'bottom'}`)}>
                                    <span className={cx('percentage-popover-container')}>
                                      CityFALCON Score showing relevance&nbsp;
                                      of story to you and your chosen topics.
                                      <a href="https://www.cityfalcon.ai/features/cf-score" rel="noopener noreferrer">
                                        <span>How does it work?</span>
                                      </a>
                                    </span>
                                  </span>
                                </div>
                                {showScorePopover && (
                                <span className={cx('popover_backdrop')} onClick={(e) => this.togglePopOver(e, 'showScorePopover')} />
                                )}
                              </>
                              )}
                              {showTranslation && isTranslationSupported && story.lang !== 'en' && !inHP && (isCompactExpandView || (isRowCardView && !isCompactView)) && (
                              <span
                                className={cx('details-header__info--translate')}
                                onMouseEnter={this.mouseEnterHandler}
                                onMouseLeave={this.mouseLeaveHandler}
                              >

                                <Tooltip
                                  className={cx('translate--tooltip')}
                                  icon={(<FontAwesomeIcon icon={currentIcon} />)}
                                  hasBigScreenDesign
                                  isBigScreen
                                >
                                  <span className={cx('title')}>
                                    {(() => {
                                      if (translation && translation.title) {
                                        if (isTranslationShown) {
                                          return (
                                            <>
                                              <div className={cx('translated--from--title')}>
                                                {`Translated from ${translationSupportedLanguages
                                                  ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                                              </div>
                                              <div
                                                className={cx('translate--title')}
                                                onClick={this.toggleTranslation}
                                              >
                                                Show Original
                                              </div>
                                            </>
                                          );
                                        }
                                        return (
                                          <>
                                            <div className={cx('translated--from--title')}>
                                              {`Original in ${translationSupportedLanguages
                                                ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                                            </div>
                                            <div
                                              className={cx('translate--title')}
                                              onClick={this.toggleTranslation}
                                            >
                                              Translate to English
                                            </div>
                                          </>
                                        );
                                      }
                                      return 'Translating to English...';
                                    })()}
                                  </span>
                                </Tooltip>
                                <span className={cx('title')}>
                                  {(() => {
                                    if (translation && translation.title) {
                                      if (isTranslationShown) {
                                        return (
                                          <>
                                            <span className={cx('translated--from--title')}>
                                              {`Translated from ${translationSupportedLanguages
                                                ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                                            </span>
                                            <span
                                              className={cx('translate--title')}
                                              onClick={this.toggleTranslation}
                                            >
                                              Show Original
                                            </span>
                                          </>
                                        );
                                      }
                                      return (
                                        <>
                                          <span className={cx('translated--from--title')}>
                                            {`Original in ${translationSupportedLanguages
                                              ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                                          </span>
                                          <span
                                            className={cx('translate--title')}
                                            onClick={this.toggleTranslation}
                                          >
                                            Translate to English
                                          </span>
                                        </>
                                      );
                                    }
                                    return 'Translating to English...';
                                  })()}
                                </span>
                              </span>
                              )}
                              {width < 768 && (!isCompactView || (isCompactView && expanded))
                            && !isRowCardView && (
                              <span className={cx('details-header__statistics')}>
                                {sentimentValue && (
                                <>
                                  <span
                                    className={cx('statistics_sentiment', { popup }, sentimentValue.type, { 'show-popover': showSentimentPopover })}
                                    onClick={(e) => this.togglePopOver(e, 'showSentimentPopover')}
                                    onMouseEnter={(e) => this.popupEnter(e, 'showSentimentPopover')}
                                    onMouseLeave={this.popupLeave}
                                  >
                                    <FontAwesomeIcon icon={sentimentValue.icon} />
                                    <span className={cx('sentiment-popover', `sentiment-popover-${isToolTipTop ? 'top' : 'bottom'}`)}>
                                      <span className={cx('sentiment-popover-container')}>
                                        Sentiment:
                                        {' '}
                                        {story.sentiment}
                                        %.
                                        <br />
                                        Ranges from -100% to +100%.
                                        {' '}
                                        <a href="https://www.cityfalcon.ai/features/sentiment" target="_blank" rel="noopener noreferrer">
                                          <span>Learn more</span>
                                        </a>
                                      </span>
                                    </span>
                                  </span>
                                  {showSentimentPopover && (
                                  <span className={cx('popover_backdrop')} onClick={(e) => this.togglePopOver(e, 'showSentimentPopover')} />
                                  )}
                                </>
                                )}
                              </span>
                              )}
                            </div>
                          )}

                          {((story.paywall && !([RBI.name].includes(clientName)) && !isMobile) || (story.registrationRequired && !isMobile) || (type === 'Tweet') || (showTranslation && story.lang !== 'en' && !inHP)) && (
                            <span className={cx('details-header__info--followerDetails')}>
                              {story.paywall && !([RBI.name].includes(clientName)) && !isMobile && (
                                <>
                                  <span
                                    className={`${cx('paywall-icon')} ${cx(showPaywallPopover ? 'show-popover' : '')} `}
                                    onClick={(e) => this.togglePopOver(e, 'showPaywallPopover')}
                                  >
                                    <FontAwesomeIcon icon={faCreditCardFront} />
                                    <span
                                      className={cx('paywall-popover', `paywall-popover-${isToolTipTop ? 'top' : 'bottom'}`)}
                                    >
                                      <span className={cx('paywall-popover-container')}>
                                        This news provider requires payment to view the story.
                                      </span>
                                    </span>
                                  </span>
                                  {showPaywallPopover && (
                                    <span className={cx('popover_backdrop')} onClick={(e) => this.togglePopOver(e, 'showPaywallPopover')} />
                                  )}
                                </>
                              )}
                              {story.registrationRequired && !isMobile && (
                                <>
                                  <span
                                    className={cx('registr-icon', { 'show-popover': showRegisterPopover })}
                                    onClick={(e) => this.togglePopOver(e, 'showRegisterPopover')}
                                  >
                                    <FontAwesomeIcon icon={faIdCard} />
                                    <span
                                      className={cx('registr-popover', `registr-popover-${isToolTipTop ? 'top' : 'bottom'}`)}
                                    >
                                      <span className={cx('registr-popover-container')}>
                                        This news provider requires registration to view the story.
                                      </span>
                                    </span>
                                  </span>
                                  {showRegisterPopover && (
                                    <span className={cx('popover_backdrop')} onClick={(e) => this.togglePopOver(e, 'showRegisterPopover')} />
                                  )}
                                </>
                              )}
                              {((type === 'Tweet' && !isCompactView) || (type === 'Tweet' && isCompactExpandView)) && (
                                <span className={cx('icons--container')}>
                                  <span className={cx('followers-count hidden-xs')}>
                                    follower / ing ratio:&nbsp;
                                    {this.getFollowersFormat(story.followers_following_ratio)}
                                  </span>
                                  <span className={cx('tweet-action hidden-xs')} onClick={() => this.tweetAction('in_reply_to')}>
                                    <FontAwesomeIcon icon={faComment} />
                                  </span>
                                  <span className={cx('tweet-action hidden-xs')} onClick={() => this.tweetAction('retweet')}>
                                    <FontAwesomeIcon icon={faRetweet} />
                                  </span>
                                  <span className={cx('tweet-action hidden-xs')} onClick={() => this.tweetAction('favorite')}>
                                    <FontAwesomeIcon icon={faHeart} />
                                  </span>
                                </span>
                              )}
                            </span>
                          )}
                          {(!isCompactView
                            && !isModal
                            && !isMobile
                            && !isTablet
                            && !isSEOPage
                            && stocksLabels?.length !== 0) && stocksLabels}
                          {(!isCompactView
                            && !isModal
                            && !isMobile
                            && !isTablet
                            && (isSEOPage && expanded)
                            && stocksLabels?.length !== 0) && stocksLabels}
                          {((!isWatchlist && !isSEOPage && (homepageStories || trendingPageStories))
                            && !isCompactView
                            && !isModal
                            && !isMobile
                            && !isTablet
                            && otherLabels?.length !== 0)
                            && (
                              otherLabels
                            )}
                          <span
                            ref={this.expandCompactViewButtonRef}
                            className={cx('expand-btn')}
                            onClick={this.toggleExpand}
                          >
                            <span className={cx('text')}>
                              {expanded ? 'Less' : 'More'}
                            </span>
                            <FontAwesomeIcon
                              className={cx('img', 'mob')}
                              icon={expanded ? faAngleUp : faAngleDown}
                            />
                          </span>
                        </span>
                        {temporaryTranslationMessage && story.lang !== 'en' && !inHP && (
                          <span
                            className={cx('details-header__info--translate-warning')}
                            onClick={(e) => e.stopPropagation()}
                          >
                            Translating, please refresh stories in ~30 seconds.
                          </span>
                        )}
                      </span>
                      {width >= 768 && (
                        <span className={cx('details-header__statistics')}>
                          {sentimentValue && (
                            <>
                              <span
                                className={cx('statistics_sentiment', { popup }, sentimentValue.type, { 'show-popover': showSentimentPopover })}
                                onClick={(e) => this.togglePopOver(e, 'showSentimentPopover')}
                                onMouseEnter={(e) => this.popupEnter(e, 'showSentimentPopover')}
                                onMouseLeave={this.popupLeave}
                              >
                                <FontAwesomeIcon icon={sentimentValue.icon} />
                                <span className={cx('sentiment-popover', `sentiment-popover-${isToolTipTop ? 'top' : 'bottom'}`)}>
                                  <span className={cx('sentiment-popover-container')}>
                                    Sentiment:
                                    {' '}
                                    {story.sentiment}
                                    %.
                                    <br />
                                    Ranges from -100% to +100%.
                                    {' '}
                                    <a href="https://www.cityfalcon.ai/features/sentiment" target="_blank" rel="noopener noreferrer">
                                      <span>Learn more</span>
                                    </a>
                                  </span>
                                </span>
                              </span>
                              {showSentimentPopover && (
                                <span className={cx('popover_backdrop')} onClick={(e) => this.togglePopOver(e, 'showSentimentPopover')} />
                              )}
                            </>
                          )}
                        </span>
                      )}
                    </div>
                    {((expanded)
                      && !isCompactView
                      && !isModal
                      && !isMobile
                      && !isTablet
                      && !homepageStories
                      && !trendingPageStories)
                      && (
                        <div className={cx('infoItemLabels', { 'infoItemLabels--available': stocksLabels?.length || otherLabels?.length })}>
                          {otherLabels}
                        </div>
                      )}
                    {isCompactExpandView && type === 'Story' && (
                      <div
                        className={!popup && !inHP ? 'details-description show' : 'details-description'}
                      >
                        {isTranslationShown && translation.description
                          ? translation.description : story.description}
                      </div>
                    )}
                    <CompactViewImageLoader
                      isCompactExpandView={isCompactExpandView}
                      story={story}
                      imageLoadingError={imageLoadingError}
                      isDesktop={isDesktop}
                      imageHeight={imageHeight}
                      imgURL={imageURL}
                      openTwitterImagesModal={this.openTwitterImagesModal}
                      onImageLoad={() => this.setState({ twitterImgLoaded: true })}
                      onImageError={this.imageErrorEvent}
                      openCurrShownImageInNewTab={this.openCurrShownImageInNewTab}
                    />
                    {isCompactExpandView && !isModal && !isMobile && !isTablet && (
                      <div className={cx('infoItemLabels')}>
                        {stocksLabels}
                        {otherLabels}
                      </div>

                    )}
                  </div>
                  {!inHP && !trendingBarCard && !([RBI.name].includes(clientName)) && (
                    <div
                      className={cx('Card__content--expand', { bookmarked: bookmarksPage })}
                    >
                      <span
                        ref={this.expandRowViewButtonRef}
                        className={cx('expand-btn')}
                        onClick={this.toggleExpand}
                      >
                        {(isMobile || type === 'Tweet' || story.description) && (
                          <span className={cx('text')}>
                            {expanded ? 'Less' : 'More'}
                          </span>
                        )}
                        <FontAwesomeIcon
                          className={cx('img', 'desktop')}
                          icon={expanded ? faAngleUp : faAngleDown}
                        />
                        <FontAwesomeIcon
                          className={cx('img', 'mob')}
                          icon={expanded ? faAngleUp : faAngleDown}
                        />
                      </span>
                    </div>
                  )}
                </div>
              )}
              { isTileCardView && (
              <TilesView
                popup={popup}
                twitterImgLoaded={twitterImgLoaded}
                userNewsfeedViewImages={userNewsfeedViewImages}
                userNewsfeedViewDescription={userNewsfeedViewDescription}
                imageLoadingError={imageLoadingError}
                allowMainImage={allowMainImage}
                allowDomainImage={allowDomainImage}
                domainImageURL={domainImageURL}
                withoutImages={withoutImages}
                story={story}
                type={type}
                expanded={expanded}
                storyImage={storyImage}
                width={width}
                openTwitterImagesModal={this.openTwitterImagesModal}
                imgURL={imageURL}
                imageLoadEvent={this.imageLoadEvent}
                imageErrorEvent={this.imageErrorEvent}
                domainImageErrorEvent={this.domainImageErrorEvent}
                storyInfoAction={this.storyInfoAction}
                isTranslationShown={isTranslationShown}
                translation={translation}
                openURL={this.openURL}
                clientName={clientName}
                isMobile={isMobile}
                showPaywallPopover={showPaywallPopover}
                togglePopOver={this.togglePopOver}
                isToolTipTop={isToolTipTop}
                showScorePopover={showScorePopover}
                showRegisterPopover={showRegisterPopover}
                sentimentValue={sentimentValue}
                showSentimentPopover={showSentimentPopover}
                popupEnter={this.popupEnter}
                popupLeave={this.popupLeave}
                stocksLabels={stocksLabels}
                otherLabels={otherLabels}
                isGoldPlan={isGoldPlan}
                isSilverPlan={isSilverPlan}
                isPremiumLabelsEnabled={storyFilters.isPremiumLabelsEnabled}
                onShowPremiumLabelHideModal={this.onShowPremiumLabelHideModal}
                isModal={isModal}
                isWatchlist={isWatchlist}
                isSEOPage={isSEOPage}
                homepageStories={homepageStories}
                trendingPageStories={trendingPageStories}
                resizeGridItem={this.resizeGridItem}
                sentimentContentRef={this.sentimentContentRef}
                showTranslation={showTranslation}
                isTranslationSupported={isTranslationSupported}
                inHP={inHP}
                translationSupportedLanguages={translationSupportedLanguages}
                toggleTranslation={this.toggleTranslation}
              />
              )}
              { ((((isMobile || isTablet) && !isCompactView)
                  || (isCompactExpandView && (isMobile || isTablet)))
                  && !isTileCardView) && (
                  <div className={cx('scrollableLabels')}>
                    <Scrollable arrowStyle="infoItemLabelsArrow">
                      <div className={cx('infoItemLabels', { 'infoItemLabels--available': stocksLabels?.length || otherLabels?.length })} ref={this.sentimentContentRef}>
                        {stocksLabels}
                        {otherLabels}
                      </div>
                    </Scrollable>
                  </div>
              )}
              { groupSimilarsFilter && showSimilarsFilter && !skipSimilarStories && (
              <SimilarStories
                hasBigScreenDesign={hasBigScreenDesign}
                similarStories={similarStories}
                openStory={this.openStory}
                openURL={this.openURL}
                closeSimilars={this.closeSimilars}
                similarStoriesCount={similarStories.allCount}
                tilesView={isTileCardView}
                width={width}
                isCompactView={isCompactView}
                storyId={story.uuid}
                expanded={expanded}
                showTranslation={showTranslation}
                isTranslationSupported={isTranslationSupported}
                inHP={inHP}
                translationSupportedLanguages={translationSupportedLanguages}
              />
              )}
              { isRowCardView && expanded && !inHP && (
              <PiwikContext.Consumer>
                {(value) => (
                  <StoryCardFooter
                    hasBigScreenDesign={hasBigScreenDesign}
                    piwikEnabled={value.piwikEnabled}
                    id={story.id}
                    story={story}
                    isDesktop={isDesktop}
                    admin={admin}
                    tags={[]}
                    duplicatesCount={story.duplicatesCount}
                    onHide={this.onHideStory}
                    toggleRemovedBookmark={this.toggleRemovedBookmark}
                    onLikeChanged={onLikeChanged}
                    onBookmarkClick={onBookmarkClick}
                    type={type}
                    curatorData={story.curator_data || {}}
                    storyFilters={storyFilters}
                    filterBySelectedSources={actions.filterBySelectedSources}
                    width={width}
                    sourcesEnabled={value.sourcesEnabled}
                    storyInfoAction={this.openStory}
                    isCompactView={isCompactView}
                    bookmarksPage={bookmarksPage}
                  />
                )}
              </PiwikContext.Consumer>
              )}
              { !withoutImages && twitterImgLoaded && !inHP && (
                <TwitterImgSliderModal
                  ref={(el) => { this.twitterImgSliderModal = el; }}
                  images={allowMainImage || !allowDomainImage
                    ? story.imageUrls : [domainImageURL]}
                  onClose={() => actions.setStoryImageOpened(false)}
                  onFreezeStories={onFreezeStories}
                />
              )}
              <SimilarStoriesHideModal
                show={storiesHideModalVisible}
                closeModal={this.closeSimilarsModal}
                onConfirm={this.hideSimilars}
              />
              <PremiumLabelHideModal
                show={showHideLabelModal}
                closeModal={this.onClosePremiumLabelHideModal}
                onConfirm={this.onHidePremiumLabel}
              />
            </div>
            { index === 2 && permissionsInitialized
              && !accessLevels.includes(GOLD) && !bookmarksPage && (
                <div className={cx(isTileCardView ? 'stories-suggestion-container-tile' : 'stories-suggestion-container')}>
                  <StoriesUpgradeSuggestion isWatchlist={isWatchlist} piwikEnabled={piwikEnabled} />
                </div>
            )}
          </>
        ) : (
          <div className={cx('removed-story')}>
            <p className={cx('removed-story-paragraph')}>Story removed</p>
            <span className={cx('removed-story-link')} onClick={(e) => this.toggleRemovedBookmark(e, story)}>Undo</span>
          </div>
        )}
        {selectedTopic && (
          <SentimentModal
            activePeriod="1w"
            selectedTopic={selectedTopic}
            slugType={selectedTopic.entity_type}
            topicId={selectedTopic.entity_id}
            topicName={selectedTopic.entity_name}
            x={selectedTopic.x}
            y={selectedTopic.y}
            arrowLeft={selectedTopic.arrowLeft}
            sentimentContentRef={this.sentimentContentRef}
            handleModalClose={this.handleModalClose}
            query={query}
            parentSentiment={selectedTopic.sentiment}
            isTopic
            isInfoLabel
            height={selectedTopic.height}
            overflowProperty={selectedTopic.overflow}
          />
        )}
      </>
    );
  }
}

StoryCard.propTypes = {
  popup: PropTypes.bool,
  isRecommendedStories: PropTypes.bool,
  useFoldedSimilarStories: PropTypes.bool,
  forceRowsView: PropTypes.bool,
  skipSimilarStories: PropTypes.bool,
};

StoryCard.defaultProps = {
  popup: false,
  isRecommendedStories: false,
  useFoldedSimilarStories: false,
  forceRowsView: false,
  skipSimilarStories: false,
};

const mapStateToProps = ({
  userPreferencesReducer,
  storyFilters,
  subscriptions: {
    permissions,
    permissionsInitialized,
  },
  watchlistReducer,
  common,
  promobar,
}) => {
  const isCompactView = isMobileOnly ? (
    storyFilters.user_newsfeed_compactview
  ) : (
    storyFilters.user_newsfeed_compactview_web
  );
  const userNewsfeedView = isMobileOnly ? (
    storyFilters.user_newsfeed_view
  ) : (
    storyFilters.user_newsfeed_view_web
  );

  return {
    curationMode: userPreferencesReducer.curationMode,
    expandFilter: storyFilters.expand_stories,
    userNewsfeedViewImages: isCompactView ? false : storyFilters.user_newsfeed_view_images_web,
    userNewsfeedViewDescription:
      isCompactView ? false : storyFilters.user_newsfeed_view_description_web,
    showSimilarsFilter: storyFilters.show_similar_stories_in_feed,
    groupSimilarsFilter: storyFilters.group_similar_stories,
    userNewsfeedView,
    userNewsfeedViewCompact: isCompactView,
    showTranslation: storyFilters.translate_all,
    permissions,
    storyFilters,
    width: common.width,
    user: userPreferencesReducer.user,
    permissionsInitialized,
    accessLevels: permissions.access_levels,
    token: watchlistReducer.userToken,
    promoHeight: promobar.height || 0,
    translationSupportedLanguages: storyFilters.translationSupportedLanguages,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...storyFiltersActions,
    ...storyActions,
    ...authentication,
  }, dispatch),
});

export default withComponentName(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(withFinprompt(StoryCard))),
);
